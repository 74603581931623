import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import Breadcrumbs from "../../common/breadcrumb/breadcrumb";
import { ReactComponent as Clock } from "../../../assests/icons/clock.svg";
import YouMayLike from "../blogs/youMayAlsoLike";
import { BLOGDETAIL } from "../../../Constants";
import Cookies from "js-cookie";
import moengage from "@moengage/web-sdk";
import MetaTagsComponent from "../metaTag/metaTagComp";



const BlogDetails = () => {
  const [blogDetail, setBlogDetail] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const location = useLocation(); 
  const query = new URLSearchParams(location.search); 
  const post_id = query.get('post_id'); 
  const BASE_URL = BLOGDETAIL;
 

  const fetchData = async () => {
    try {
      if (!post_id) {
        throw new Error("Post ID is missing");
      }

      // Create URLSearchParams instance
      const payload = new URLSearchParams({
        BlogId: post_id
      });

      // Make the POST request
      const response = await fetch(BASE_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: payload.toString(),
      });

      // Check if the response is okay
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Parse and set JSON data
      const data = await response.json();
      setBlogDetail(data.Result[0]);
    
        moengage.track_event("Blogs_Viewed", {
          "ID": (Cookies.get("Otp")) ? `+91${JSON.parse(Cookies.get("Otp")).MobileNo}` :"", 
          "Category Name": data.Result[0]?.BlogCategory,
          "Blog Name": data.Result[0]?.Title
          });
      
    } catch (error) {
      console.error('Failed to fetch data:', error);
      setError('Failed to fetch data.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (post_id) {
      fetchData();
    

   


    } else {
      setError('No post ID provided.');
      setLoading(false);
    }
    window.scrollTo(0, 0);
  }, [post_id]);

  if (loading) return <div className="loader">Loading...</div>;
  if (error) return <div className="error">{error}</div>;

  let spaceRemoval = () => {
    let spaceRemove = document.querySelector(".blog__breadcrumb span:last-child");
    if (spaceRemove) {
      spaceRemove.innerHTML = spaceRemove.innerHTML.replace(/-/g, ' ');
    }
  };
  
  // Call the function to perform the replacement
  spaceRemoval();
  
  return (
    <>
    <MetaTagsComponent />
      <div className="blog__detail__wrapper page-width">
        <div className="blog__breadcrumb"><Breadcrumbs blog={"/blogs/all-blogs"}/></div>
        <div className="blog__detail__container">
          <div className="blog__left__content">
          <div className="blog__detail__heading"><h1>{blogDetail.Title}</h1></div>
          <div className="blog__detail__author">
            <div className="blog__author__name">by {blogDetail.UserName || "Admin"}</div>
            <div className="single__line"></div>
            <div className="blog__read__time"><Clock  />2 minute read</div>
          </div>
          {blogDetail ? (
            <div className="blog__detail__content">
              <img src={blogDetail.WebHomeBanner} alt={blogDetail.title} />
              <br/>
              <p dangerouslySetInnerHTML={{
                        __html:  blogDetail.WebContent,
                      }}></p> 
    <br/>
              <span>{blogDetail.author}</span>
              <span>{blogDetail.date}</span>
            </div>
          ) : (
            <div>No blog details available.</div>
          )}
          </div>
          <div className="blog__right__content">
            {/* <div className="blog__right__heading">The Latest Blog</div> */}
            <div className="blog__right__container">
              <div className="blog__right__container__item">
                  {/* <div className="blog__right__content__wrapper active">
                    <div className="blog__right__content__item">
                      <p>The Ultimate List of Top 10 Sports Nutrition Brands for Fitness Enthusiasts in India – 2024</p>
                    </div>
                    <div className="blog__detail__author">
                    <div className="blog__author__name">by {blogDetail.UserName || "Admin"}</div>
                      <div className="single__line"></div>
                      <div className="blog__read__time"><Clock />2 minute read</div>
                    </div>
                  </div>

                  <div className="blog__right__content__wrapper">
                    <div className="blog__right__content__item">
                      <p>The Ultimate Guide to Choosing the Healthiest Peanut Butter</p>
                    </div>
                    <div className="blog__detail__author">
                    <div className="blog__author__name">by {blogDetail.UserName || "Admin"}</div>
                      <div className="single__line"></div>
                      <div className="blog__read__time"><Clock />2 minute read</div>
                    </div>
                  </div>

                  <div className="blog__right__content__wrapper">
                    <div className="blog__right__content__item">
                      <p>How To Buy The Greatest Whey Protein In India</p>
                    </div>
                    <div className="blog__detail__author">
                    <div className="blog__author__name">by {blogDetail.UserName || "Admin"}</div>
                      <div className="single__line"></div>
                      <div className="blog__read__time"><Clock />2 minute read</div>
                    </div>
                  </div> */}

                  {/* <div className="blog__right__content__wrapper">
                    <div className="blog__right__content__item">
                    <p>The Ultimate List of Top 10 Sports Nutrition Brands for Fitness Enthusiasts in India – 2024</p>
                    </div>
                    <div className="blog__detail__author">
                    <div className="blog__author__name">by {blogDetail.UserName || "Admin"}</div>
                      <div className="single__line"></div>
                      <div className="blog__read__time"><Clock />2 minute read</div>
                    </div>
                  </div> */}
                  
                  {/* Blog News Letter */}
                  {/* <div className="blog__right__newsletter">
                    <div className="blog__newsltter__heading">
                      Subscribe to our newsletter and receive a selection of cool articles every weeks
                    </div>
                    <div className="blog__newsltter__content">
                      <div className="blog__newsletter__input">
                        <input type="text" placeholder="Enter your email"></input>
                        <div className="blog__newletter__submit">Subscribe</div>
                      </div>
                      <div className="blog__right__checkbox">
                        <div className="blog__newsletter__checkbox">
                          <input type="checkbox" className="checkbox"/>
                          <p>By checking this box, you confirm that you have read and are agreeing to our terms of use regarding the storage of the data submitted through this form.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  
              </div>
            </div>

          </div>
        </div>
        {/* You May Also Like */}
        <YouMayLike post_id={post_id}/>
      </div>
    </>
  );
};

export default BlogDetails;
