import "./orderHistoryItem.css";

import reOrder from "../../../../assests/icons/rotate-left.svg";

import { Link } from "react-router-dom";
import { ADD_TO_CART, fetchRegisterId } from "../../../../Constants";
import moengage from "@moengage/web-sdk";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

const OrderHistoryItem = ({ historyDeatils, setOrderID_details }) => {
  var elementColor;
  


  function statusType(status,OrderStatus){
    if(status=='aborted'){
      return ["Failed",0]
    }

    switch (OrderStatus) {
      case 'cancelled':
         return ["Cancelled",4]

      case 'pending':
         return ["Order Placed",1]

      case 'delivered':
        return ["Delivered",2]

      default :
        return ["Shipped",3]
          
    }

  }
  var value = statusType(historyDeatils?.Status,historyDeatils?.OrderStatus)[1];
 
 if ( value === 4 || value === 0) {
    elementColor = "itemStatus pending";
  } else {
    elementColor = "itemStatus completed";
  }
  const addToCart = async (productId) => {

    try {
      let RegisterId = fetchRegisterId()
      if(RegisterId){
              const response = await fetch(ADD_TO_CART, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  ProductId: productId,
                  RegisterId,
                  ProductQuantity: 1, // Adjust as needed, depending on how quantity is managed
                  ApplicationType: "web"
                }),
              });

              if (!response.ok) {
                throw new Error(
                  `Failed to add product to cart! Status: ${response.status}`
                );
              }
      }


    
    } catch (error) {
      console.error("Error adding product to cart:", error);
      //   toast.error("Error adding product to cart");
    }
  };

  const handleReorder = (id) => {
    addToCart(id);
    let productDeatils = historyDeatils.Products.filter((p) => p.ProductId === id)
    moengage.track_event("Re_Orders_Initiated", {
      "ID": `+91${JSON.parse(Cookies.get("Otp")).MobileNo}` , 
   "Product ID" : productDeatils[0].ProductId,
   "Product Name": productDeatils[0].Title,
   "Price": productDeatils[0].Price,
   
   [productDeatils[0].Variant1Name ? productDeatils[0].Variant1Name  :""] :productDeatils[0].Variant1Value,
   ...(productDeatils[0].Variant2Name  !== "" && {  [productDeatils[0].Variant2Name ] : productDeatils[0].Variant2Value }),
   "USIN": productDeatils[0].USIN ? productDeatils[0].USIN :"" ,
   "Quantity":productDeatils[0].ProductQuantity

      });

  };

let status= statusType(historyDeatils?.Status,historyDeatils?.OrderStatus)
//console.log(historyDeatils.Products)
  return (
    <div className="historyItemWrapper">
      <div className="orderIdWrapper">
        <div className="idDetails">
          <div className="orderIdName"> Order Id </div>
          <div className="idText "> #{historyDeatils.OrderId}</div>
        </div>
        <div className={elementColor} id="itemStatus">
      {statusType(historyDeatils?.Status,historyDeatils?.OrderStatus)[0]}
        </div>
      </div>
      {historyDeatils.Products &&
        historyDeatils.Products.map((product,index) => (
          <div className="orderDetailsWrapper" key={product.id || index}>
            <div className="detailBox">
              <div className="detailsContent">
                <Link
                  to={`/order-status/order?id=${historyDeatils.OrderId}`}
                  className="linkDetails"
                  onClick={() => setOrderID_details(historyDeatils)}
                >
                  <div className="history__item__image">
                    <img
                      src={product.CoverImage}
                      alt="No Image"
                      className="imgShow"
                       loading="lazy"
                    />
                  </div>

        { product &&         <div className="orderDetails">
                    <div className="history__item__title">{product.Title}</div>
                    <div className="actual_price">₹{ product.UnitPrice}</div>
                    <div className="orderedDate">{product.Date}</div>
                    <div className="orderedDate">Quantity : {product.ProductQuantity}</div>
                  </div>}
                </Link>

                <Link className="reOrderWrapper " to={"#"} onClick={(()=>{
                   handleReorder(product.ProductId)
                    window.open(product?.ProductURL?.replace("https://www.","https://"), "_blank", "noopener,noreferrer");
                })}>
                  <button
                    className="StartShopping__btn btn_primary"
                    onClick={() => handleReorder(product.ProductId)}
                  >
                    {" "}
                    <img src={reOrder} className="location__icon" loading="lazy" />
                    Re-Order
                  </button>
                </Link>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};
export default OrderHistoryItem;
