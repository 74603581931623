import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

function ReferalCode() {
	const navigate = useNavigate()

useEffect(() => {
	window.location.href ="https://unfitmart.page.link/RaBWL8y1AQ1YCMyE6"
	navigate("https://unfitmart.page.link/RaBWL8y1AQ1YCMyE6")
},[])

  return (
	<div>


	  
	</div>
  )
}

export default ReferalCode
