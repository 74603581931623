import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css'; 
import Maintenance from './pages/Maintenance';
import fetchUnderMaintenance from './api/fetchUnderMaintenance';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import store from './redux/store';

 const result = await fetchUnderMaintenance(); 
//const result = "no" 
const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

root.render(
  <HelmetProvider>
  <React.StrictMode>

    {result === "no" ?   <Provider store={store}> <App /></Provider>  : <Maintenance /> }
  </React.StrictMode>
  </HelmetProvider>

);
