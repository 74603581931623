import React, { useEffect,useState } from "react";
import { useLocation } from "react-router-dom";
import startIcon from "../../assests/icons/ratingStar.png";
import "./unfitStorePage2.css";
import CallIcon from "../../assests/icons/CallOrange.png";
import Location from "../../assests/icons/LocationIcon.png";
import MsgIcon from "../../assests/icons/Message.png";
import img1 from "../../assests/icons/originalIcon.png";
import img2 from "../../assests/icons/certifiedIcon.png";
import img3 from "../../assests/icons/secureIcon.png";
import coinIcon from "../../assests/icons/coinIcon.png";
import dietPlanIcon from "../../assests/icons/dietPlanIcon.png";
import { ReactComponent as Line } from "../../assests/icons/line.svg";
import HmSlider from "../../components/common/bannerSlider/homeSlider/hmSlider";
import AvailableBoxs from "./AvailableBoxs";
import { fetchStoreDetails } from "./fetchUnfitStoreApi";
import { useDispatch, useSelector } from "react-redux";
import { setStoreDetails } from "../../redux/slice";
import StoreTimings from "./StoreTimings";
import CustomLoader from "../../components/common/customLoader/customLoader";
function StoreDetailPage() {
  const dispatch = useDispatch();

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("storeId");

  const storeDetails = useSelector((state) => state.filters.storeDetails);
  
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768); // Assuming 1024px as desktop width
  const [isLoading,setIsLoading] = useState(true)

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 1024); // Update state based on screen width
    };

    window.addEventListener('resize', handleResize); // Listen for resize events
    return () => window.removeEventListener('resize', handleResize); // Clean up event listener
  }, []);
  let response;
  const loadCity = async () => {
    //setLoading(true);
    try {
      response = await fetchStoreDetails(id);
      //console.log("response : ",response);

      dispatch(setStoreDetails(response));
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      // setLoading(false); // Ensure loading is false after data fetch or error
      setTimeout(() => {
        setIsLoading(false)
      }, 2500);
      
    }
  };

  useEffect(() => {
    loadCity();
  }, []);
if(isLoading){
 return <CustomLoader/>
}
  return (
    <div className="page-2-container ">
      <HmSlider data={storeDetails.WebMediaFiles} />

      <div className="page-width ">
        <div className="page-2-wrapper ">
          <div className="page-width detail-contanier ">
            <div className="store-title-wrapper ">
              <h3>
                {storeDetails.StoreName} 
              </h3>
              <div className="rating-box">
                <img alt="star" className="icon" src={startIcon} />
                <p>{storeDetails.Rating } ({storeDetails.RatingCount } Reviews)</p>
              </div>
            </div>
            <div className="blog__post__line">
              <Line />
            </div>
            {storeDetails?.StoreCategory.length > 0 && (
              <AvailableBoxs categories={storeDetails.StoreCategory} />
            )}
           {storeDetails?.StoreCategory.length > 0 && <div className="blog__post__line">
              <Line />
            </div>}
            {!isDesktop && <div className="store-timing-container  ">
            <div className="store">
              <div className="store-info">
                <div className="store-info-details">
                  <img src={Location} alt="Location Icon" />
                  <p>{storeDetails.Address}</p>
                </div>
                <div className="store-info-details">
                  <img src={CallIcon} alt="Location Icon" />
                  <p>{storeDetails.PhoneNumber}</p>
                </div>
                <div className="store-info-details">
                  <img src={MsgIcon} alt="Location Icon" />
                  <p>{storeDetails.Email}</p>
                </div>
              </div>
              <div className="blog__post__line">
                <Line />
              </div>
             {storeDetails.StoreHours && <StoreTimings StoreHours={storeDetails.StoreHours} />}
              <div className="logos">
                  <img src={storeDetails.StaticImage}/>
              </div>
            </div>
          </div>}
            <div className="payments-wrapper ">
              <h6>Payment Mode Available at Store</h6>
              <div className="payment-card-wrapper">
                {storeDetails.StorePaymentMethod &&
                  storeDetails.StorePaymentMethod.map((payment,index) => (
                    <div className="card-box-wrapper" key={index}>
                      <img src={payment.MethodImage} />
                      <h6>{payment.MethodName}</h6>
                    </div>
                  ))}
              </div>
            </div>
            <div className="blog__post__line">
              <Line />
            </div>
            {storeDetails.StoreBrand.length > 0 && (
              <AvailableBoxs brands={storeDetails.StoreBrand} />
            )}
            {storeDetails.StoreBrand.length > 0 && <div className="blog__post__line">
              <Line />
            </div> }
            <div className="more-services">
              <h6>More Services</h6>
              <div className="key-points">
                <img src={coinIcon} />
                Earn & Redeem uCoin at Store
              </div>
              <div className="key-points">
                <img src={dietPlanIcon} />
                Advance Diet Plan & Fitness Plan
              </div>
            </div>
          </div>
          {isDesktop && <div className="store-timing-container  ">
            <div className="store">
              <div className="store-info">
                <div className="store-info-details">
                  <img src={Location} alt="Location Icon" />
                  <p>{storeDetails.Address}</p>
                </div>
                <div className="store-info-details">
                  <img src={CallIcon} alt="Location Icon" />
                  <p>{storeDetails.PhoneNumber}</p>
                </div>
                <div className="store-info-details">
                  <img src={MsgIcon} alt="Location Icon" />
                  <p>{storeDetails.Email}</p>
                </div>
              </div>
              <div className="blog__post__line">
                <Line />
              </div>
             {storeDetails.StoreHours && <StoreTimings StoreHours={storeDetails.StoreHours} />}
              <div className="logos">
                <img src={storeDetails.WebStaticImage}/>
                {/* <div className="logo-wrap">
                  <img src={img1} alt="Logo 1" />
                 <p>100% Original</p>
                </div>
                <div className="logo-wrap">
                  <img src={img2} alt="Logo 1" />
                  <p>100% Original</p>
                </div>
                <div className="logo-wrap">
                  <img src={img3} alt="Logo 1" className="width-less" />
                  <p>100% Original</p>
                </div> */}
              </div>
            </div>
          </div>}
          
        </div>
      </div>
    </div>
  );
}

export default StoreDetailPage;
