import React, { useState, useEffect } from "react";
import "./account.css"; // Ensure you have the appropriate CSS file
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import {ReactComponent as WishlistIcon} from "../../../../assests/icons/WishList.svg";
import { ReactComponent as UserIcon } from "../../../../assests/icons/Account.svg"; // Adjust path as needed
import { ReactComponent as AddressIcon } from "../../../../assests/icons/Address.svg"; // Adjust path as needed
import { ReactComponent as OrderIcon } from "../../../../assests/icons/Order.svg"; // Adjust path as needed
import Breadcrumbs from "../../../common/breadcrumb/breadcrumb"; // Adjust path as needed
import UpdateAddress from "./address";
import {
  fetchRegisterId,
  fetchUserData,
  WEB_EDIT_PROFILE,
  CHECK_USER_NEW,
} from "../../../../Constants";
import OrderHistory from "../../checkout/orderHistory/orderHistory";
import { cross_black } from "../../../../utils/image";
import AddToCartList from "../../cart/addToCartList";
import { validateAccountForm } from "./validate";
import moengage from "@moengage/web-sdk";
import WishList from "./wishlist";

const Account = ({
  displayLoginPages,
  setDisplayLoginPages,
  fetchAddress,
  setOrderID_details,
}) => {
  //console.log("In account page")
  const [selectedMenuItem, setSelectedMenuItem] = useState("account-info");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    mobileNo: "",
    email: "",
    gender: "Male", // Default value for gender
  });
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });
  let span;
  useEffect(() => {
    span = document.getElementsByClassName("close")[0];
    const userData = fetchUserData();
    if (userData) {
      const userDataObj = fetchUserData(); //JSON.parse(userData);
      const [firstName = "", lastName = ""] = (
        userDataObj.userName || ""
      ).split(" ");
      //console.log(userDataObj);
      if (displayLoginPages && displayLoginPages.displayAccountPage) {
        //setDisplayAccountPage(false);
        //setDisplayAddressForm(true)
        //console.log("closing")
        modal.style.display = "none";
        //console.log("Account verifing now closing");
        //   toast.success("Welcome Back! Logged In ")

        setDisplayLoginPages((prevState) => ({
          ...prevState,
          displayAccountPage: false,
          accountVerified: true,
        }));
        //console.log("fetchAddress")
        fetchAddress();
        //console.log(displayLoginPages)
      }

      setFormData({
        firstName: userDataObj.firstName || firstName,
        lastName: userDataObj.lastName || lastName,
        mobileNo: userDataObj.MobileNo || userDataObj.phoneNumber,
        email: userDataObj.email || "",
        gender: userDataObj.gender || "Male",
      });

      //console.log(userData)
    }
  }, []);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const pathname = location.pathname;
    let path = pathname.split('/')
   //console.log(path[path.length-1])
    moengage.track_event("User_Proflie_Clicked", {
      "ID": `+91${JSON.parse(Cookies.get("Otp")).MobileNo}` ,  
     "Category Name":path[path.length-1]
      

      });
   
    if (pathname.includes("address")) {
      setSelectedMenuItem("address-info");
    } else if (pathname.includes("order")) {
      setSelectedMenuItem("order-info");
    } else if (pathname.includes("wishlist")) {
      setSelectedMenuItem("wishlist-info");
    } else if (pathname.includes("user")) {
      setSelectedMenuItem("user");
    } else {
      setSelectedMenuItem("account-info");
      const userData = fetchUserData();
      //console.log(userData)
      if(userData){
        //console.log(userData)
        let splitName;
        if(userData?.userName)
 {splitName = userData?.userName.split(" ");}


        moengage.track_event("Account Info_Viewed", {
          "ID": `+91${JSON.parse(Cookies.get("Otp"))?.MobileNo}` ,  
         "First Name" : userData?.userName? splitName.slice(0, -1).join(" ") : userData.firstName ,
         "Last Name" : userData?.userName ? splitName[splitName.length - 1] : userData.lastName,
        "Email id" : userData?.email,
         "Gender" : userData?.gender
    
          });
      }

    }

  }, [location.pathname]);

  const handleMenuItemClick = (menuItem, url) => {
    setSelectedMenuItem(menuItem);
    navigate(url);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    //console.log(value)
    if (value === "") {
      setErrors({
        ...errors,
        [name]: "This field is Required",
      });
    } else {
      setErrors({ ...errors, [name]: "" });
    }

    setFormData({
      ...formData,
      [name]: value,
    });

    //console.log(errors)
  };

  // const updateA

  useEffect(() =>{
    let addressInfo = document.querySelector(".account__menu__item.account__info");
    if (window.location.href.includes("/account/user")) {
      if (addressInfo) {
        addressInfo.classList.add("active");
      }else{
        addressInfo?.classList.remove("active");
      }
    }
  },[location]);

  const saveData = () => {
    // let userData = Cookies.get('userData');
    let registerID = fetchRegisterId();
    let mobileNo = `+91${JSON.parse(Cookies.get("Otp")).MobileNo}`

    //console.log(`+91${JSON.parse(Cookies.get('Otp')).MobileNo}`,`${formData.firstName}`,`+91${JSON.parse(Cookies.get('Otp')).MobileNo}`)

    moengage
      .add_unique_user_id(mobileNo)
      .then();
    moengage.add_first_name(`${formData.firstName}`);
    moengage.add_last_name(`${formData.lastName}`);
    moengage.add_mobile(mobileNo);
    moengage.add_email(`${formData.email}`);
    moengage.add_user_name(`${formData.firstName} ${formData.lastName}}`); // Full name for user
    moengage.add_gender(`${formData.gender}`);


    //console.log(formData)

    Cookies.set(
      "userData",
      JSON.stringify({
        firstName: formData.firstName,
        lastName: formData.lastName,
        MobileNo: mobileNo || "",
        email: formData.email,
        gender: formData.gender,
        id: registerID || "",
      })
    );
  };

  const updateProfile = async () => {
    try {
      const userData = fetchUserData(); //JSON.parse(Cookies.get('userData'));

      const response = await fetch(WEB_EDIT_PROFILE, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          FirstName: userData.firstName,
          LastName: userData.lastName,
          EmailId: userData.email,
          RegisterId: fetchRegisterId(),
          MobileNumber: `+91${JSON.parse(Cookies.get("Otp")).MobileNo || ""}`,
          Gender: userData.gender,
        }),
      });

      let data = await response.json();
      //console.log(data.Result[0]);
      if (data.Status) {
        if (data.Result[0]) {
          Cookies.set("resigterId", JSON.stringify(data.Result[0]));
          moengage.track_event("Web_Signed_Up", {
            "ID": `+91${JSON.parse(Cookies.get("Otp")).MobileNo}` ,  
            "First Name":userData.firstName,
            "Last Name": userData.lastName,
            "Email id": userData.email,
            "Gender": userData.gender,
            "Customer Id": data.Result[0].RegisterId
            
      
            });
        }
        userCheck();
      } else {
        //console.log(data.StatusMessage);
        setErrors({ common: `${data.StatusMessage}` });
        return false;
      }

      if (!response.ok) {
        throw new Error(`Failed to update profile! Status: ${response.status}`);
      }
      return true;
      //console.log('Profile updated successfully');
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  const userCheck = async () => {
    try {
      const userData = fetchUserData(); //JSON.parse(Cookies.get('userData'));
      let otp = JSON.parse(Cookies.get("Otp"));
      //console.log(userData)
      const response = await fetch(CHECK_USER_NEW, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          MobileNo: `+91${userData.MobileNo}`,
          Code: otp.otp,
        }),
      });

      if (!response.ok) {
        throw new Error(`Failed to verify user! Status: ${response.status}`);
      }
      //console.log('User verified successfully');
      AddToCartList();
    } catch (error) {
      console.error("Error verifying user:", error);
    }
  };
//on submiting user details
  const handleSubmit = async () => {
    if (validateAccountForm(formData, setErrors)) {
      //after verifying , saving data in moengage and cookies
      saveData();
      //will update or create new profile and then create register id 

      let updateProfilee = await updateProfile();
      
      if (!updateProfilee) {
        //break;
        //console.log("Wrong user");
        Cookies.remove("userData")
      } else {
        if (displayLoginPages && displayLoginPages.displayAccountPage) {
          setSelectedMenuItem("user");
          modal.style.display = "none";
          setDisplayLoginPages((prevState) => ({
            ...prevState,
            displayAccountPage: false,
            accountVerified: true,
          }));
          //console.log(displayLoginPages)
        } else {
          navigate("/account/user");
          setTimeout(()=>{
            window.location.reload();
          },200)
        }
      }
    }
  };

 

  const handleEdit = () => {
    setSelectedMenuItem("account-info");
    saveData();
  };
  let modal = document.getElementById("myModal");

  const handleClose = () => {
    modal.style.display = "none";
    setDisplayLoginPages({
      ...displayLoginPages,
      displayAccountPage: false,
    });
  };
  const renderContent = () => {
    switch (selectedMenuItem) {
      case "account-info":
        return (
          <div className="user__profile">
            <div className="user__info">
              <div className="account__info">
                <div className="account_page_title">
                  <div className="address__main__heading">
                    <h5>Account Information</h5>
                  </div>
                  <span className="close" onClick={handleClose}>
                    <img src={cross_black} alt="Close Icon" />
                  </span>
                </div>
                <div className="account_card_right">
                  <div className="account__form">
                    <div className="account__phone__box">
                      <label className="account__phone__head">
                        First Name<span className="red">*</span>
                      </label>
                      <input
                        required
                        type="text"
                        placeholder="Enter First Name"
                        name="firstName"
                        id="firstName"
                        value={formData.firstName}
                        onChange={handleInputChange}
                         pattern="[A-Za-z]+" title="Please enter letters only."
                      />
                      {errors.firstName && (
                        <span className="red">
                          {errors.firstName}
                        </span>
                      )}
                    </div>
                    <div className="account__phone__box">
                      <label className="account__phone__head">
                        Last Name<span className="red">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Last Name"
                        name="lastName"
                        id="lastName"
                        value={formData.lastName}
                        onChange={handleInputChange}
                      />
                      {errors.lastName && (
                        <span className="red">
                           {errors.lastName}
                        </span>
                      )}
                    </div>
                    <div className="account__phone__box">
                      <label className="account__phone__head">
                        Phone Number<span className="red">*</span>
                      </label>
                      <input
                        type="number"
                        placeholder="Enter Your Number"
                        name="mobileNo"
                        id="mobileNo"
                        disabled={fetchUserData()?.MobileNo ? true : false}
                        value={JSON.parse(Cookies.get("Otp")).MobileNo || ""}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="account__phone__box">
                      <label className="account__phone__head">
                        Email<span className="red">*</span>
                      </label>
                      <input
                        type="email"
                        placeholder="Enter Your Email"
                        name="email"
                        id="email"
                        value={formData.email}
                        disabled={
                          fetchUserData()?.email && !errors?.common
                            ? true
                            : false
                        }
                        onChange={handleInputChange}
                      />
                      {errors.email && (
                        <span className="red"> {errors.email}</span>
                      )}
                    </div>
                    <div className="account__phone__box">
                      <label className="account__phone__head">Gender</label>
                      <select
                        className="select__gender"
                        name="gender"
                        value={formData.gender}
                        onChange={handleInputChange}
                      >
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                  </div>
                  {errors.common && (
                    <span className="red"> {errors.common}</span>
                  )}
                  <div className="account__edit__btn" onClick={handleSubmit}>
                    Submit
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case "address-info":
        return <UpdateAddress />;
      case "user":
        return (
          <div className="user__profile__data">
            <div className="user__profile__main">
              <div className="edit__profile__item">
                <div className="user__profile__head">Edit Profile</div>
                <div className="account__edit__btn" onClick={handleEdit}>
                  Edit
                </div>
              </div>
              <div className="user__profile__main-item">
                <div className="user__first__name flex gap20 user__width">
                  <label className="user__first__text">First Name:</label>
                  <div className="user__first__value">{formData.firstName}</div>
                </div>
                <div className="user__last__name flex gap20 user__width">
                  <label className="user__last__text">Last Name:</label>
                  <div className="user__last__value">{formData.lastName}</div>
                </div>
                <div className="user__email flex gap20 user__width">
                  <label className="user__email__text">Email:</label>
                  <div className="user__email__value">{formData.email}</div>
                </div>
                <div className="user__mobile flex gap20 user__width">
                  <label className="user__mobile__text">Mobile No:</label>
                  <div className="user__mobile__value">{formData.mobileNo}</div>
                </div>
                <div className="user__gender flex gap20 user__width">
                  <label className="user__gender__text">Gender:</label>
                  <div className="user__gender__value">{formData.gender}</div>
                </div>
              </div>
            </div>
          </div>
        );
      case "order-info":
        return (
          <div className="order__info">
            <OrderHistory setOrderID_details={setOrderID_details} />
          </div>
        );
      // case "wishlist-info":
      //   return (
      //     <div className="wishlist__info">
      //       <h5>Wishlist</h5>
      //       <WishList />
      //     </div>
      //   );
      default:
        return null;
    }
  };

  return (
    <div className="account__wrapper">
      <div className="account__container page-width">
        {!setDisplayLoginPages && <Breadcrumbs />}
        <div className="account__main__container">
          {!setDisplayLoginPages && (
            <div className="account__left__content">
              <div className="account__main__heading">Account Dashboard</div>
              <div className="account__main__menu">
                <div
                  className={`account__menu__item account__info ${
                    selectedMenuItem === "account-info" ? "active" : ""
                  }`}
                  onClick={() =>
                    handleMenuItemClick("account-info", "/account") || handleMenuItemClick("account-info", "/account/user")
                  }
                >
                  <UserIcon />
                  Account Information
                </div>
                <div
                  className={`account__menu__item address__info ${
                    selectedMenuItem === "address-info" ? "active" : ""
                  }`}
                  onClick={() =>
                    handleMenuItemClick("address-info", "/account/address")
                  }
                >
                  <AddressIcon />
                  Address
                </div>
                <div
                  className={`account__menu__item order__info ${
                    selectedMenuItem === "order-info" ? "active" : ""
                  }`}
                  onClick={() =>
                    handleMenuItemClick("order-info", "/account/orders")
                  }
                >
                  <OrderIcon />
                  Order
                </div>
                {/* <div
                  className={`account__menu__item wishlist__info ${
                    selectedMenuItem === "wishlist-info" ? "active" : ""
                  }`}
                  onClick={() =>
                    handleMenuItemClick("wishlist-info", "/account/wishlist")
                  }
                >
                  <WishlistIcon /> 
                  Wishlist
                </div> */}
              </div>
            </div>
          )}

          <div className="account__right__content">{renderContent()}</div>
        </div>
      </div>
    </div>
  );
};

export default Account;
