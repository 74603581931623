import { toast } from "react-toastify";
import { WEB_PLACE_ORDER, WEB_PLACE_ORDER_STATUS,WEB_ORDER,fetchRegisterId } from "../../../Constants";
import moengageEvent from "./moengageEvent";
import CryptoJS from 'crypto-js';

const PLACE_ORDER_API_URL = WEB_PLACE_ORDER;


const hostname = window.location.origin;
// const isLocalhost = hostname.includes('localhost');
const isAdmin = hostname.includes('https://unfitmart.com');

const RAZORPAY_KEY = isAdmin
  ? 'rzp_live_4a5W92OaFvKV4d' // Test API Key 
  : 'rzp_test_tM8lG1QCIBtuxH'; // Live API Key

export const loadRazorpayScript = () => {
  return new Promise((resolve, reject) => {
    if (document.querySelector('script[src="https://checkout.razorpay.com/v1/checkout.js"]')) {
      resolve();
      return;
    }
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.onload = () => resolve();
    script.onerror = () => reject(new Error('Failed to load Razorpay script'));
    document.body.appendChild(script);
  });
};

const secretKey = "your-very-strong-secret-key";

const encryptData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
};

const createOrder = async ({ items, total, navigate, userDataObj, displayAddresss,setManipulatedOrder,setFailedOrder ,appliedCoupon}) => {
  try {
    await loadRazorpayScript();
    
    // const orderData = {
    //   RegisterId: userDataObj.id || fetchRegisterId(),
    //   OrderId: '',
    //   PromoCode: items.CouponDiscount,
    //   SubTotal: items.SubTotal || 0,
    //   arrayTotalDiscountPrice: items.FinalPayableAmount || 0,
    //   TotalPayableAmount: items.FinalPayableAmount || 0,
    //   TransactionId: "",
    //   Address: `${displayAddresss.houseNumberAndStreet},${displayAddresss.landmark},${displayAddresss.city},${displayAddresss.state},${displayAddresss.country}-${displayAddresss.pincode}.`, // Replace with actual data if available  ,
    //   AddressId: "",
    //   CurrentLat: 23.08108108108108,
    //   CurrentLng: 72.52638566558649,
    //   CouponDiscount: items.CouponDiscount,
    //   WalletAmount: "",
    //   PaymentType: "Prepaid",
    //   Country: "India",
    //   UsedWalletBalance: "",
    //   Product: JSON.stringify(items.Result.map(item => ({
    //     ProductId: item.ProductId || "",
    //     FinalPriceWithQty: item.FinalPriceWithQty || 0,
    //     FinalDiscountPriceWithQty: item.FinalDiscountPriceWithQty || 0,
    //     productVariantId: item.productVariantId || ""
    //   })))
    // };

        const orderData = {
      RegisterId: userDataObj.id || fetchRegisterId(),
       PaymentType: "Prepaid",
      PromoCode: appliedCoupon,
      TotalPayableAmount: items.FinalPayableAmount || 0,
    }

    // Place order via API
    const response = await fetch(PLACE_ORDER_API_URL, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(orderData),
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Failed to place order! Status: ${response.status}, Message: ${errorText}`);
    }

    const result = await response.json();
//order was placed and order_id generated 
    if(result.Status && result.Result.order_id ){
      const orderIds =  result.Result.order_id ;
      const transactionResult = await updateOrderStatus({ orderIds, result });
      if(transactionResult.Status){
        const transactionId = transactionResult.id;
        // Initiate Razorpay payment
        const options = {
          key: `${RAZORPAY_KEY}`,
        
          currency: "INR",
          name: "unFIT",
          description: "Order Payment",
          order_id: transactionId, // Use transaction ID from updateOrderStatus
          handler: async (response) => {
        
            let transactionOrder = response.razorpay_order_id;
            let razorpay_payment_id = response.razorpay_payment_id;
    
            placeOrderStatus({ transactionOrder, razorpay_payment_id,result ,items,navigate});
         
          },
          prefill: {
            name: userDataObj.name,
            email: userDataObj.email,
            contact: userDataObj.phone,
          },
          theme: {
            color: "#3399cc",
          },
        };
    
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    
        return orderIds;

      }

    }
    // order_id not generated or pay amount does not matches from actual amount
    else{
      //toast("Order was cancelled");
      setFailedOrder(true)
      //navigate('/');
    }
    
  

    // Update order status and get transaction ID
 

  } catch (error) {
    console.error('Error placing order:', error.message);
    // Handle error (e.g., show error message to user)
  }
};

const updateOrderStatus = async ({ orderIds,result }) => {
  const orderStatusApi = WEB_ORDER;
  try {
    const results = await fetch(orderStatusApi, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        OrderIds: orderIds,
    
      }),
    });
    if (!results.ok) {
      const errorText = await results.text();
      throw new Error(`Failed to update order status! Status: ${results.status}, Message: ${errorText}`);
    }

    const statusResult = await results.json();


    // Return transaction ID for Razorpay
    return statusResult;

  } catch (error) {
    console.error('Error updating order status:', error.message);
    throw error; // Re-throw error to be handled by caller
  }
};

const placeOrderStatus = async ({ response, transactionOrder, razorpay_payment_id,result,items,navigate }) => {
  const orderStatusApi = WEB_PLACE_ORDER_STATUS;
  try {
    const results = await fetch(orderStatusApi, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        TransactionOrderId: transactionOrder, // Use the actual order ID
        TransactionId: razorpay_payment_id
      }),
    });

    if (!results.ok) {
      const errorText = await results.text();
      throw new Error(`Failed to update order status! Status: ${results.status}, Message: ${errorText}`);
    }

    const placeStatusResult = await results.json();
    if(placeStatusResult){
      moengageEvent(items,"PrePaid");
      trackPurchaseDataLayer(transactionOrder,items);
      navigate("/thankyou", { state: { orderDetails: result } });
    }
    else{
      //console.log("failed")
    }
  
  } catch (error) {
    console.error('Error updating order status:', error.message);
    // Handle error (e.g., show error message to user)
  }
};


// Data layer Purchase Event
const trackPurchaseDataLayer = (transactionOrder,items) => {
  const itemss = items?.Result?.map((item, index) => ({
    item_id: item.USIN || "",
    item_name: item.PorductName || "",
    affiliation: "UnfitMart",
    coupon: "SUMMER_FUN",
    discount: item.PriceQuantity[0]?.Note ? parseFloat(item.PriceQuantity[0].Note.replace(/[()]/g, "")) : 0,
    index: index,
    item_brand: item.BrandName || "",
    item_category: item.CategoryName || "",
    item_category2: item.SubCategoryName || "",
    item_list_id: "purchase",
    item_list_name: "Purchase Page",
    item_variant: item.variant1_value || "",
    location_id: item.locationId || "ChIJIQBpAG2ahYAR_6128GcTUEo",
    price: item.PriceQuantity[0].Price || 10.01,
    quantity: item.ProductQuantity || 1
  }));

  trackCheckoutPurchase(items);

  // Calculate the total value of the purchase
  const totalValue = itemss?.reduce((sum, item) => sum + item.price * item.quantity, 0);

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "purchase",
    ecommerce: {
      currency: "INR",
      value: items.FinalPayableAmount,
      transaction_id: transactionOrder || "", 
      items: itemss
    }
  });
};

const trackCheckoutPurchase = (items) => {
  const contents = items?.Result?.map(item => ({
    id: item.id || '', 
    quantity: item.ProductQuantity || 1 
  }));
 
  const value = items?.FinalPayableAmount || 0; 
  const metaProductID = items?.Result?.map(item => item.USIN || '');
  const currency = 'INR';
  import('react-facebook-pixel')
    .then(module => {
      const ReactPixel = module.default;
      ReactPixel.track('Purchase', {
        contents,
        content_ids: metaProductID,
        content_type: 'product',
        value,
        currency
      });
    })
    .catch(err => {
      console.error("Failed to load Facebook Pixel for tracking Purchase:", err);
    });
};

export default createOrder;
