// import React, { useState, useEffect, useRef } from 'react';
// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// // import ProductCard from '../../layout/product/productCard/brandPlacementCard';
// import { useMediaQuery } from "react-responsive";
// import '../../layout/product/productCard/productCard.css';
// import Loader from '../loader/loadingCard';
// import Logo from "../../../assests/logo/unfitLogoWhite.png";
// import { Link } from "react-router-dom";
// import Cookies from "js-cookie";
// import moengage from "@moengage/web-sdk";
// import ProductCard from '../../layout/product/productCard/productCard';

// const ExtraProdSlider = ({ categoryName, categoryId,setIsToastMessage,setCountItems,data }) => {

//   const [loading, setLoading] = useState(true);
//   const [page, setPage] = useState(1);
//   const isMounted = useRef(false);
//   const [currentSelected, setCurrentSelected] = useState([]);

//   useEffect(() => {
//     if (!isMounted.current) {
//       isMounted.current = true;
//       loadProducts(page);
//     }
//   }, [page]);

//   const loadProducts = async (page) => {
//     setLoading(true);
//     try {
//       //const fetchedProducts = await fetchProducts(categoryId, categoryName, page);
//      // const fetchedBannerData = await fetchBannerData(categoryName);

//      // setProducts(fetchedProducts);
//       //setHomeBanner(fetchedBannerData);
//       setLoading(false);
//     } catch (error) {
//       console.error('Error fetching data:', error);
//       setLoading(false);
//     }
//   };

//   const sliderSettings = {
//     dots: false,
//     infinite: false,
//     speed: 500,
//     slidesToShow: 3.5,
//     slidesToScroll: 1,
//     arrows: true,
//     touchThreshold: 100,
//     responsive: [
//       { breakpoint: 1440, settings: { slidesToShow: 4, slidesToScroll: 1 } },
//       { breakpoint: 1024, settings: { slidesToShow: 3, slidesToScroll: 1 } },
//       { breakpoint: 850, settings: { slidesToShow: 2, slidesToScroll: 1 } },
//     ]
//   };

//   const brandPlacement = data
//   const brandPlacementProduct = brandPlacement?.ProductList || [];

//   const handleInitialAddToCart = (product) => {
//     let USINData = product?.VariantData && product.VariantData[0] && product.VariantData[0].USIN || product?.PriceQuantity && product.PriceQuantity[0] && product.PriceQuantity[0].USIN;
//     setCurrentSelected((prev) => {
//       if (prev.includes(USINData)) {
//         return prev.filter((each) => each !== USINData);
//       } else {
//         return [USINData];
//       }
//     });
//   };

//   const handleMoenageEvent = (BrandName) => {

//       moengage.track_event("Brands_Viewed", {
//         "ID": (Cookies.get("Otp")) ? `+91${JSON.parse(Cookies.get("Otp")).MobileNo}` :"",
//         "Brand Name": BrandName,
//         "Screen Name" : "Brand Placement"

//         });

//   }

//   return (
//     <div className="hm__product-container section_space bolt_nutritions hm__prod__slider" style={{
//       backgroundImage:
//         "url('https://unfit-app-files.s3.ap-south-1.amazonaws.com/stage/images/brand_images/Z3lxQMWnf6Nc.jpg')",
//       backgroundSize: "cover",
//       backgroundRepeat: "no-repeat",
//       backgroundPosition: "center",
//     }}>
//       {loading ? (
//         <div className="loading-spinner">
//           <div className='product-list'>
//             <Loader />
//             <Loader />
//             <Loader />
//             <Loader />
//             <Loader />
//           </div>
//         </div>
//       ) : (
//         <div className='slider_container cm_arrow_side'>
//           <div className="extra__card__item">
//             <div className='extra__product__card-item'>
//               <div className="extra__product__card-inner" style={{ backgroundColor: brandPlacement?.card_color }}>
//                 <div className="card" >
//                   <img src={brandPlacement?.brandLogo || Logo} alt={brandPlacement?.brandName} />
//                   <p className="brandplacement__name">{brandPlacement?.BrandName}</p>
//                   <p>{brandPlacement?.brandDescription}</p>
//                   <Link to={`/brands/${brandPlacement?.BrandName?.replace(/\s+/g, "-")?.toLowerCase()}`+`?id=${brandPlacement?.id}`} onClick={() => handleMoenageEvent(brandPlacement?.BrandName)}>
//                     <div className="buy__button buy__now__btn">Shop Now</div>
//                   </Link>
//                 </div>
//               </div>
//             </div>
//             <Slider className="bolt_nutri_slide" {...sliderSettings}>
//               {brandPlacementProduct.map((product) => (
//                 <ProductCard
//                  key={product.id}
//                   product={product}
//                   BrandPlacementProduct={brandPlacementProduct}
//                    onAddtoCart={handleInitialAddToCart}
//                   currentSelected={currentSelected}
//                   setIsToastMessage={setIsToastMessage}
//                   setCountItems={setCountItems}
//                   from={"Homepage_Brand_Placement_Viewed"}
//                   screenName={brandPlacement?.BrandName}
//                   isOutOfStock={product?.PriceQuantity[0]?.InStock === "no"}/>
//               ))}
//             </Slider>
//           </div>
//         </div>
//       )
//       }
//     </div >
//   );
// }

// export default ExtraProdSlider;

import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import ProductCard from '../../layout/product/productCard/brandPlacementCard';
import "../../layout/product/productCard/productCard.css";
import Loader from "../loader/loadingCard";
import Logo from "../../../assests/logo/unfitLogoWhite.png";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { useMediaQuery } from "react-responsive";
import moengage from "@moengage/web-sdk";
import ProductCard from "../../layout/product/productCard/productCard";
import "./extraProductSlider.css";
const ExtraProdSlider = ({
  categoryName,
  categoryId,
  setIsToastMessage,
  setCountItems,
  data,
}) => {
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const isMounted = useRef(false);
  const [currentSelected, setCurrentSelected] = useState([]);

  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const isDesktop = useMediaQuery({ query: "(min-width: 768px)" });

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      loadProducts(page);
    }
  }, [page]);

  const loadProducts = async (page) => {
    setLoading(true);
    try {
      //const fetchedProducts = await fetchProducts(categoryId, categoryName, page);
      // const fetchedBannerData = await fetchBannerData(categoryName);

      // setProducts(fetchedProducts);
      //setHomeBanner(fetchedBannerData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const sliderSettings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3.5,
    slidesToScroll: 1,
    touchThreshold: 100,
    responsive: [
      { breakpoint: 1440, settings: { slidesToShow: 4, slidesToScroll: 1 } },
      { breakpoint: 1024, settings: { slidesToShow: 3, slidesToScroll: 1 } },
      { breakpoint: 850, settings: { slidesToShow: 3, slidesToScroll: 1 } },
      { breakpoint: 767, settings: { slidesToShow: 2, slidesToScroll: 1 } },
    ],
  };

  const brandPlacement = data;
  const brandPlacementProduct = brandPlacement?.ProductList || [];

  const handleInitialAddToCart = (product) => {
    const USINData =
      product?.VariantData?.[0]?.USIN || product?.PriceQuantity?.[0]?.USIN;
    setCurrentSelected((prev) =>
      prev.includes(USINData)
        ? prev.filter((each) => each !== USINData)
        : [USINData]
    );
  };

  const handleMoenageEvent = (BrandName) => {
    moengage.track_event("Brands_Viewed", {
      ID: Cookies.get("Otp")
        ? `+91${JSON.parse(Cookies.get("Otp")).MobileNo}`
        : "",
      "Brand Name": BrandName,
      "Screen Name": "Brand Placement",
    });
  };

  const slidersData = [
    {
      id: 1,
      backgroundImage: isDesktop ? data?.WebBanner : data?.AppBannner,
      productList: data?.ProductList?.slice(0, 5) || [],
      brandLogo: data?.brandLogo || Logo,
      brandName: data?.BrandName,
      brandDescription: data?.brandDescription,
    },
  ];

  return (
    <div className="page-width">
      {slidersData.map((slider) => (
        <div
          key={slider.id}
          className="hm__product-container section_space bolt_nutritions hm__prod__slider extra-slider-container  "
          style={{
            backgroundImage: `url(${slider.backgroundImage})`,
            }}
        >
          {loading ? (
            <div className="loading-spinner">
              <div className="product-list">
          
              </div>
            </div>
          ) : (
            <div className="slider_container cm_arrow_side" >
              <div className="extra__card__item" style={{ display:"flex" ,flexDirection: "column",
              justifyContent:"space-between"  , position: "absolute"}}>

               { false && <div className="extra__product__card-item">
                  <div
                    className="extra__product__card-inner"
                  >
                    <div className="card">
                      <img src={slider.brandLogo} alt={slider.brandName} />
                      <p className="brandplacement__name">{slider.brandName}</p>
        
                    </div>
                  </div>
                </div>}

                <Slider className="bolt_nutri_slide" {...sliderSettings}>
                  {slider.productList.map((product) => (
                    <ProductCard
                      key={product.id}
                      product={product}
                      BrandPlacementProduct={slider.productList}
                      onAddtoCart={handleInitialAddToCart}
                      currentSelected={currentSelected}
                      setIsToastMessage={setIsToastMessage}
                      setCountItems={setCountItems}
                      from={"Homepage_Brand_Placement_Viewed"}
                      screenName={slider.brandName}
                      isOutOfStock={
                        product?.PriceQuantity?.[0]?.InStock === "no"
                      }
                    />
                  ))}
                </Slider>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default ExtraProdSlider;
