import { fetchRegisterId, WEB_ADDRESS } from "../Constants";

 
export const fetchExistingAddress = async () => {
      try {
        let registerID = fetchRegisterId();
      
       
      
          //   if (!userDataObj.id) {
          //     let registerId = JSON.parse(Cookies.get("resigterId"));
          //     registerID = registerId.RegisterId;
          //   } else {
          //     registerID = userDataObj.id;
          //   }

          const response = await fetch(WEB_ADDRESS, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              RegisterId: registerID,
            
            }),
          });

          if (!response.ok) {
            throw new Error(`Failed to fetch address! Status: ${response.status}`);
          }

          const fetchedAddress = await response.json();
          const existingAddress = fetchedAddress.Result[0];
        return existingAddress;
      
      } catch (error) {
       //console.log(error.message);
      }
    };
 
