// src/api/ProductListApi.js

import { WEBHOMEPAGE ,DASHBOARD} from "../Constants";


const BASE_URL = WEBHOMEPAGE;

let cachedBannerData = null;
let fetchPromise = null;
let cachedDashBoardData = null;
let fetchDashBoardDataPromise = null;

export const fetchBannerData = async () => {
  if (cachedBannerData) {
    return cachedBannerData;
  }

  if (fetchPromise) {
    return fetchPromise;
  }

  fetchPromise = new Promise(async (resolve, reject) => {
    try {
      const payload = new URLSearchParams({
        Pagination: '',
        groupCategoryId: '',
        LevelType: ''
      });

      const response = await fetch(BASE_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: payload,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      cachedBannerData = data || [];
      resolve(cachedBannerData);
    } catch (error) {
      reject(error);
    } finally {
      fetchPromise = null;
    }
  });

  return fetchPromise;
};

export const fetchDashBoardData = async (id) => {
  if (cachedDashBoardData) {
    return cachedDashBoardData;
  }

  if (fetchDashBoardDataPromise) {
    return fetchDashBoardDataPromise;
  }

  fetchDashBoardDataPromise = new Promise(async (resolve, reject) => {
    try { 
      let payload;
     
         payload = new URLSearchParams({
          DashboardId: id 
        });
      
     

      const response = await fetch(DASHBOARD, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        
        ...(id !== undefined && {body : payload})
        
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      cachedDashBoardData = data|| [];
      //console.log(data.result)
      resolve(cachedDashBoardData);
    } catch (error) {
      reject(error);
    } finally {
      fetchDashBoardDataPromise = null;
    }
  });

  return fetchDashBoardDataPromise;
};