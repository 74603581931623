import moengage from "@moengage/web-sdk";
import Cookies from "js-cookie";
import variantArray from "../../common/variantArray";

const moengageCheckOutEvent = (items, type) => {
  let data = {};
  const firstKey = items.Result[0]?.variant1_name;

//console.log(items)
  




// const checkVariant = (v1name, v1value, v2name, v2value) => {
//     const firstKey = Object.keys(data)[0];
  
//     // Add v1value to v1name array
//     if (v1name in data) {
//       data[v1name].push(v1value);
//     } else {
//       data[v1name] = v1name !== "" ? (data[firstKey] ? new Array(data[firstKey].length).fill(null) : []) : [];
//       data[v1name].push(v1value);
//     }
  
//     // Add v2value to v2name array
//     if (v2name in data) {
//       data[v2name].push(v2value);
//     } else {
//       data[v2name] = v2name !== "" ? (data[firstKey] ? new Array(data[firstKey].length - 1).fill(null) : []) : [];
//       data[v2name].push(v2value);
//     }
  
//     // Ensure all arrays have the same length
//     const maxLength = Math.max(...Object.values(data).map((arr) => arr.length));
//     Object.keys(data).forEach((key) => {
//       while (data[key].length < maxLength) {
//         data[key].push(null);
//       }
//     });
//   };

  let ProductName = [],
    ProductID = [],
    BasePrice = [],
    PromotionPrice = [],
    Quantity = [],
    DiscountPercentage = [],
    USIN = [];
  ///console.log(items);
  let variant1_name, variant2_name;
  items.Result.map((item) => {
    ProductName.push(item.PorductName);
    ProductID.push(item.ProductId);
    BasePrice.push(item.TotalMrp);
    PromotionPrice.push(item.TotalPrice);

    if (item.variant2_name !== "" && typeof item.variant2_name !== undefined) {
      //console.log(item.variant1_name,item.variant1_value,item.variant2_name,item.variant2_value)
      variantArray(
        data,
        firstKey,
        item.variant1_name,
        item.variant1_value,
        item.variant2_name,
        item.variant2_value,

      );
    } else if (
      item.variant1_name !== "" &&
      typeof item.variant1_name !== undefined
    ) {
      //console.log(item.variant1_name,item.variant1_value)
      variantArray(data,firstKey,item.variant1_name, item.variant1_value);
    }
    Quantity.push(item.ProductQuantity);
    DiscountPercentage.push(parseInt(item.Note.replace("%", "").split(" ")[1]));
    USIN.push(item.USIN ? item.USIN : "");
    variant1_name = item.variant1_name;
    variant2_name = item.variant2_name;
    moengage.track_event("Check_out_Product", {
      ID: `+91${JSON.parse(Cookies.get("Otp")).MobileNo}`,
      "Product Name": item.PorductName,
      "Product ID": item.ProductId,
      "Base Price": item.TotalMrp,
      "Promotion Price": item.TotalPrice,
      [item.variant1_name ? item.variant1_name : ""]: item.variant1_value,
      ...(item.variant2_name !== "" && {
        [item.variant2_name]: item.variant2_value,
      }),
      USIN: item.USIN ? item.USIN : "",
      "Discount Percentage": parseInt(item.Note.replace("%", "").split(" ")[1]),
      Quantity: item.ProductQuantity,
     

      "Customer Id": JSON.parse(Cookies.get("userData")).id
        ? JSON.parse(Cookies.get("userData")).id
        : JSON.parse(Cookies.get("resigterId")).RegisterId,
    });
  });

//console.log(data)

  moengage.track_event("Check_Out_Summary", {
    ID: `+91${JSON.parse(Cookies.get("Otp")).MobileNo}`,
    "Product Name": ProductName,
    "Product ID": ProductID,
    "Base Price": BasePrice,
    "Promotion Price": PromotionPrice,
    // [item.variant1_name ? item.variant1_name  :""] :item.variant1_value,
    // ...(item.variant2_name  !== "" && {  [item.variant2_name ] : item.variant2_value }),
     "USIN": USIN ,
     "Discount Percentage" :DiscountPercentage,
    "Quantity" : Quantity,
    OrderType: type,
    "Total Pay" : items.FinalPayableAmount,
    "Coupon Discount" : items.CouponDiscount,
    "Customer Id": JSON.parse(Cookies.get("userData")).id
      ? JSON.parse(Cookies.get("userData")).id
      : JSON.parse(Cookies.get("resigterId")).RegisterId,
    "Customer Name": JSON.parse(Cookies.get("userData")).userName
      ? JSON.parse(Cookies.get("userData")).userName
      : JSON.parse(Cookies.get("userData")).firstName +
        " " +
        JSON.parse(Cookies.get("userData")).lastName,
        ...data
  });
};
export default moengageCheckOutEvent;
