import React, { useState, useEffect } from "react";
//import { Link } from "react-router-dom";
import "./brandGrid.css";
import {BrandList} from "../brands/brandApi";
import Loader from "../loader/loadingCard";
import BrandMetaTag from "../../../components/layout/metaTag/metaTagComp";


const BrandGridPage = () => {
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(true);
  //const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  //const [visibleBrands, setVisibleBrands] = useState(8);

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const brandData = await BrandList();
        const flattenedBrands = Object.values(brandData).flat();
        setBrands(flattenedBrands);
      } catch (error) {
        console.error("Error fetching brands:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBrands();

    const handleResize = () => {
      //setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  

  if (loading) {
    return (
      <div className="loading-spinner">
        <div className="product-list">
          <Loader />
          <Loader />
          <Loader />
          <Loader />
          <Loader />
        </div>
      </div>
    );
  }

  return (
    <>
      <BrandMetaTag />
      <div className="brand__grid__wrapper padding-top">
        <div className="brand__grid__component ban__container page-width">
          <div className="brand__grid">
            {brands.slice(0, -1).map((brand, index) => (
              <div key={index} className="brand__content">
                <a
                  href={`/brands/${brand.name?.replace(/\s+/g, "-").replace(/[^a-zA-Z0-9-]/g, "")
                    .toLowerCase()}?id=${brand.id}`}
                  className="brand__link"
                >
                  <div className="brand__img">
                    <img src={brand.image} alt={brand.name} />
                  </div>
                  <div className="brand__bottom">
                    <img src={brand.logo} alt={brand.name} />
                  </div>
                </a>
              </div>
            ))}
          </div>
          {/* {visibleBrands < brands.length && (
            <div className="loadmore__btn brand__page" onClick={loadMore}>
              Load More
            </div>
          )} */}
        </div>
      </div>
    </>
  );
};

export default BrandGridPage;
