import React from "react";
import ratingStarIcon from "../../assests/icons/ratingStar.png";
import locationIcon from "../../assests/icons/Location.png";
import callIcon from "../../assests/icons/Call.png";
import { Link } from "react-router-dom";
import moengage from "@moengage/web-sdk";
import Cookies from "js-cookie";
import urlReplace from "../../services/urlReplace";
function StoreBox({ store }) {
  let desktop_video = document.querySelector(".desktop__video");
  let mobile_video = document.querySelector(".mobile__video");
  let play_Video = document.querySelector(".play_labell");

  let handlePlayBtn = () => {
    desktop_video?.play();
    play_Video?.classList.add("hide");
  };
  window.addEventListener("load", () => {
    desktop_video?.play(); // Start playing automatically when the page loads
  });
  const handleOpenGoogleMaps = (endLat, endLng) => {
    const googleMapsUrl = `https://www.google.com/maps/dir//${endLat},${endLng}`;
    window.open(googleMapsUrl, "_blank"); // Open in a new tab
  };
  //console.log(store);
  const handleClick = (phoneNumber) => {
    window.location.href = `tel:${phoneNumber}`;
  };
  const handleMoengageEvent = (store) => {
 
		moengage.track_event("Particular_Store_Clicked", {
			"ID": (Cookies.get("Otp")) ? `+91${JSON.parse(Cookies.get("Otp")).MobileNo}` :"", 
      "Select City" :store.City,
"Store Name" : store?.StoreName,
"Address" : store?.Address,
"Phone No" : store.PhoneNumber

		  });  	
	}
  return (
    
      <div className="store-box-container">
        <Link to={`/unfit-store/${urlReplace( store?.StoreName && store?.StoreName.split("(")[1])}?storeId=${store.id}`} onClick={() => handleMoengageEvent(store)} className="store-details">
        
          {!store?.MediaFile.endsWith("mp4") ? (
            <img
              src={store?.MediaFile}
              alt="product__img"
              className="store-image play_labell"
            />
          ) : (
            <video
              id="video"
              src={store?.MediaFile}
              className="store-image desktop__video"
              autoplay
              controls
              loop
            />
          )}
          {/* <img src={store?.MediaFile} className="store-image" /> */}
          <div className="store-detail">
            <div className="store-header">
              <h5>{store?.StoreName} </h5>
              <div className="rating-star">
                <img src={ratingStarIcon} />{" "}
                <p>{store.Rating ? store.Rating : "4.3"}</p>
              </div>
            </div>

            <p>
              {store?.Address.length < 75
                ? store?.Address
                : store?.Address.slice(0, 75) + "..."}
            </p>
            <div className="store-hours">
              <p>Store Hours:-</p>
              <div className="p2">{store.StoreHours}</div>
            </div>
          </div>
          </Link>

        <div className="btn-container">
          <button
            className="product_atc_btn"
            onClick={() =>
              handleOpenGoogleMaps(store.Latitude, store.Longitude)
            }
          >
            <img src={locationIcon} className="icon" />
            Location
          </button>
          <button
            className="product_atc_btn"
            onClick={() => handleClick(store.PhoneNumber.slice(3))}
          >
            <img src={callIcon} className="icon" />
            {store.PhoneNumber.slice(3)}
          </button>
        </div>
      </div>
  
  );
}

export default StoreBox;
