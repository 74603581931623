import React,{useState} from "react";
// import PlusIcon from "../../assests/icons/plus.svg";
import ShippingIcon from "../../assests/icons/truck.svg";
import moengage from "@moengage/web-sdk";
import Cookies from "js-cookie";

const PincodeValidator = ({productInStock}) => {
  const [pincode,setPincode] =  useState("");
  const [showDates,setShowDates] = useState(false);


  function handlePincodeChange(){
    if(pincode?.length === 6){
   
        moengage.track_event("Pincode_Check", {
            "ID": (Cookies.get("Otp")) ? `+91${JSON.parse(Cookies.get("Otp")).MobileNo}` :"", 
            "Pincode": pincode
           });
      



      setShowDates(true)
    }
    else{
      setShowDates('NotValid');
    }
  }


  return (
    <div className="pincode__container pdp_other_section">
      <label className=" pdp_other_label option__name">
        Select Delivery Location
      </label>
      <div className="pincode__validator__item">
          <input type="number" max="999999" min="0" step={1} placeholder={productInStock?"Please Enter Your Pincode":""} value={pincode} disabled={!productInStock} onChange={(e) => setPincode(e.target.value)}/>
          <button className="pincode__submit" onClick={handlePincodeChange}  disabled={!productInStock}>Check</button>
      </div>
      {showDates === true && <div className="pincode__desc">
            <img src={ShippingIcon} alt="truck" />
            <div className="pincode__text green">Estimated delivery: 3 to 5 days </div>
        </div>}
        {showDates === 'NotValid' && <div className="pincode__desc">
            <div className="pincode__text red">Please enter a valid 6 digit pincode.</div>
        </div>}
    
    </div>
  );
};

export default PincodeValidator;
