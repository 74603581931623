import React from "react";
import { Link } from "react-router-dom";
import moengage from "@moengage/web-sdk";
import { left_icon } from "../../utils/image";
import { fetchRegisterId, WEB_CART_DELETE } from "../../Constants";
import Cookies from "js-cookie";

const CartButtons = () => {

  const DELETE_API_URL = WEB_CART_DELETE;

  const handleRemoveAll = async () => {
    try {
            const RegisterId = fetchRegisterId()
      const response = await fetch(DELETE_API_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
       
          RegisterId,
          CartListId: 'all',
        }),
      });

      if (!response.ok) {
        throw new Error(
          `Failed to remove cart item! Status: ${response.status}`
        );
      }
      if(Cookies.get("Otp")){
      moengage.track_event(`Remove_All_Cart`, {
        "ID": `+91${JSON.parse(Cookies.get("Otp")).MobileNo}`,
      
      
      });
    }
      setTimeout(() => {
        window.location.reload();
      }, 2000);
     
    } catch (error) {
      //console.log(error.message);
    }
  };

  return (
    <div className="cart_bottom_btns">
      <div className="buy__now__btn">
        <Link to={'/'} className="btn_primary buy__now__btn orange">
          <img src={left_icon} alt="left icon" />
          Back To Shop
        </Link>
      </div>
      <button className="btn_primary btn_border_btn add__to_cart__btn" onClick={handleRemoveAll}>
        Remove All
      </button>
    </div>
  );
}

export default CartButtons;
