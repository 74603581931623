import React from 'react'

function urlReplace(name) {
//console.log(name)
if (name)
{return	name.replace(/[^a-zA-Z0-9.]/g, "-") // Replace non-alphanumeric characters with hyphens
	.replace(/-+/g, "-")            // Replace multiple hyphens with a single hyphen
	.replace(/^-|-$/g, "")          // Remove leading or trailing hyphens
	.toLowerCase()  }  
	else return ""
}

export default urlReplace
