import React, { useState, useEffect } from 'react';
import backTopImage from "../../assests/image/backtoTop.svg";
import backTopGrey from "../../assests/image/backToGrey.svg";

// import { ReactComponent as backTopImage } from '../../assests/image/backtoTop.svg';

const BackToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scrolled down
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Scroll to top smoothly
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    checkPageReload();
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);


  function checkPageReload() {
    const isReloaded = sessionStorage.getItem('isReloaded');
    document.querySelector('.back-to-top button')?.click(); // Simulate button click

    if (isReloaded) {
      document.querySelector('.back-to-top button')?.click(); // Simulate button click
    } else {
      sessionStorage.setItem('isReloaded', true);
    }
  }
  
  // Call the function when the page loads
  window.onload = checkPageReload;

  return (
    <div className="back-button">
      <div className="back-to-top">
        {isVisible && (
          <button
            onClick={scrollToTop}
            className={`${window.location.href.includes('product') ? 'hide' : ''}`}
          >
            {/* Display first image by default, and hide on hover */}
            <img src={backTopImage} alt="backtop" className="backtop-image first-image" />
            {/* Second image hidden by default and shown on hover */}
            <img src={backTopGrey} alt="backtop" className="backtop-image second-image" />
          </button>
        )}
      </div>
    </div>
  
  );
};

const styles = {
  button: {
    position: 'fixed',
    bottom: '75px',
    right: '40px',
    padding: '10px 20px',
    fontSize: '18px',
    backgroundColor: '#fff',  // Background color as white
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    zIndex: 1000,
    boxShadow: '0px 4px 10px 0px rgba(27, 26, 39, 0.25)',  // Correct box shadow format
  },
};


export default BackToTopButton;
