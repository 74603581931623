import Cookies from "js-cookie";
import { fetchRegisterId, WEB_PLACE_ORDER } from "../../../Constants";
import moengageEvent from "./moengageEvent"

const placeOrder = async ({ items, total, navigate, userDataObj,setLoadingCOD, displayAddresss, setconfirmCODPopUp, setFailedOrder, setOrder,setManipulatedOrder,appliedCoupon }) => {
  let resigterId = fetchRegisterId();




  let failedmodal = document.getElementById("failedModal");

  try {
    // Generate OrderId dynamically or fetch from another service
    const orderId = `ORD-${Date.now()}`; // Example of generating a unique OrderId based on timestamp

    // Define the order data
    // const orderData = {
    //   RegisterId: resigterId,
    //   OrderId: orderId,
    //   DelhiveryCharges : items.DelhiveryCharges,
    //   PromoCode: items.CouponDiscount, // You can dynamically assign this if applicable
    //   SubTotal: items.SubTotal || 0,
    //   arrayTotalDiscountPrice: items.FinalPayableAmount || 0,
    //   TotalPayableAmount: items.FinalPayableAmount || 0,
    //   TransactionId: "", // Should be dynamically fetched or provided if needed
    //   Address: `${displayAddresss.houseNumberAndStreet},${displayAddresss.landmark},${displayAddresss.city},${displayAddresss.state},${displayAddresss.country}-${displayAddresss.pincode}.`, // Replace with actual data if available  ,
    //   AddressId: "", // Should be dynamically fetched or provided
    //   CurrentLat: '', // Replace with actual latitude if available
    //   CurrentLng: '', // Replace with actual longitude if available
    //   CouponDiscount: items.CouponDiscount, // Example value; replace with actual discount if applicable
    //   WalletAmount: "", // Example value; replace with actual wallet balance if applicable
    //   PaymentType: "COD",
    //   Country: "India",
    //   UsedWalletBalance: "", // Example value; replace with actual wallet balance if applicable
    //   Product: JSON.stringify(items.Result.map(item => ({
    //     ProductId: item.ProductId || "unknown", // Replace with actual ProductId
    //     FinalPriceWithQty: item.FinalPriceWithQty || 0,
    //     FinalDiscountPriceWithQty: item.FinalDiscountPriceWithQty || 0,
    //     productVariantId: item.productVariantId || "unknown"
    //   })))
    // };



    const orderData = {
      RegisterId: resigterId,
       PaymentType: "COD",
      PromoCode: appliedCoupon,
      TotalPayableAmount: items.FinalPayableAmount || 0,
    }
    // Send request to the API
    const response = await fetch(WEB_PLACE_ORDER, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(orderData),
    });

    // Handle API response
    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Failed to place order! Status: ${response.status}, Message: ${errorText}`);
    }

    const result = await response.json();
 
    setOrder(result.Status);
    // Redirect to the order confirmation page
    // navigate("/order-confirmed", { state: { orderDetails: result } });
    if(result.Status && result.Result.order_id )
    {
      moengageEvent(items,"COD")
      let transactionOrder = result.Result.order_id
      trackPurchaseDataLayer(transactionOrder,items);
      navigate('/thankyou');
    }else{
      setFailedOrder(true);
    }
    
   
setLoadingCOD(false);

  } catch (error) { 
    console.error('Error placing order:', error.message);
  }
};

// Data layer Purchase Event
const trackPurchaseDataLayer = (transactionOrder,items) => {
  const itemss = items.Result?.map((item, index) => ({
    item_id: item.USIN || "",
    item_name: item.PorductName || "",
    affiliation: "UnfitMart",
    coupon: "SUMMER_FUN",
    discount: item.PriceQuantity[0]?.Note ? parseFloat(item.PriceQuantity[0].Note.replace(/[()]/g, "")) : 0,
    index: index,
    item_brand: item.BrandName || "Google",
    item_category: item.CategoryName || "",
    item_category2: item.SubCategoryName || "",
    item_list_id: "purchase",
    item_list_name: "Purchase Page",
    item_variant: item.variant1_value || "",
    location_id: item.locationId || "ChIJIQBpAG2ahYAR_6128GcTUEo",
    price: item.PriceQuantity[0].Price || 10.01,
    quantity: item.ProductQuantity || 1
  }));

  
  trackCheckoutPurchase(items);

  // Calculate the total value of the purchase
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "purchase",
    ecommerce: {
      currency: "INR",
      value: items.FinalPayableAmount,
      transaction_id: transactionOrder || "", 
      items: itemss
    }
  });  
};

const trackCheckoutPurchase = (items) => {
  const contents = items?.Result?.map(item => ({
    id: item.id || '', 
    quantity: item.ProductQuantity || 1 
  }));
 
  const value = items?.FinalPayableAmount || 0; 
  const metaProductID = items?.Result?.map(item => item.USIN || '');

  const currency = 'INR';

  import('react-facebook-pixel')
    .then(module => {
      const ReactPixel = module.default;
      ReactPixel.track('Purchase', {
        contents,
        content_ids: metaProductID,
        content_type: 'product',
        value,
        currency
      });
    })
    .catch(err => {
      console.error("Failed to load Facebook Pixel for tracking Purchase:", err);
    });
};



export default placeOrder;
