




 const variantArray =  (data,firstKey,v1name, v1value, v2name, v2value) => {

        if (v1name in data) {
          data[v1name].push(v1value);
        } else {
          if (JSON.stringify(data) !== "{}" && v1name !== "") {
            data[v1name] = new Array(data[firstKey].length).fill(null);
            data[v1name].push(v1value);
          } else {
            data[v1name] = [v1value];
          }
        }
    
        if (v2name in data) {
          data[v2name].push(v2value);
        } else {
          if (
            JSON.stringify(data) !== "{}" &&
            v1name !== "" &&
            data[firstKey].length > 1
          ) {
            data[v2name] = new Array(data[firstKey].length - 1).fill(null);
            data[v2name].push(v2value);
          } else if (v2name !== "" && v2name !== undefined) {
            data[v2name] = [v2value];
          }
        }
    
        // Ensure all arrays have the same length
        const maxLength = Math.max(...Object.values(data).map((arr) => arr.length));
        Object.keys(data).forEach((key) => {
          while (data[key].length < maxLength) {
            data[key].push(null);
          }
        });
    
        //console.log(data)
      };
 

  export default variantArray