import React from "react";
import { footer_benner, footer_benner_mob } from "../../../utils/image";

const ImageWrap = ({data}) => {

  return (
    <div className="hm__image__wrapper__sec">
      <picture>
        <source media="(min-width: 851px)" srcSet={data.WebMediaFile} />
        <source media="(max-width: 850px)" srcSet={data.AppMediaFile} />
        <img
          src={data.WebMediaFile || ""}
          alt="Footer section illustration"
          className="footer-image"
        />
      </picture>
    </div>
  );
};

export default ImageWrap;
