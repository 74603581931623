import React, { useEffect, useState } from "react";
import {
  cross_black,
  g_star,
  logo_orange,
  Star_icon,
  y_star,
} from "../../../utils/image";
import { useParams } from "react-router-dom";
import {
  fetchRegisterId,
  Product_Rating,
  PRODUCT_RATING_LIST,
  fetchUserData,
} from "../../../Constants";
import { toast } from "react-toastify";

const ReviewDetail = () => {
  const { productId, variant1, variant2 } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [reviews, setreviews] = useState([]);
  const [TotalReview, setTotalReview] = useState();
  const [Total, setTotal] = useState();
  const [isAddReview, setIsAddReview] = useState(false);
  const [reviewDetails, setReviewDetails] = useState({
    Rating: 0,
    Review: "",
    Title: "",
  });
  const [reviewError, setReviewError] = useState({});
  const fetchData = async () => {
    try {
      const payload = new URLSearchParams({
        ProductId: productId,
        page: currentPage,
      });

      const response = await fetch(PRODUCT_RATING_LIST, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: payload,
      });

      if (!response.ok) {
        throw new Error(
          `Failed to fetch product details! Status: ${response.status}`
        );
      }

      const data = await response.json();
      setreviews(data.Result);
      setTotalReview(data.TotalReview);
      setTotal(data.Total);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [productId, variant1, variant2]);

  const toggleAddReviewPopup = () => {
    setIsAddReview(!isAddReview);
  };
  const handleReviewChange = (stars) => {
    setReviewDetails({
      ...reviewDetails,
      Rating: stars,
    });
    let errors = reviewError;
    delete errors.Rating;
    setReviewError(errors);
  };
  const onReviewChange = (field, value) => {
    reviewDetails[field] = value;
    reviewError[field] = checkValidation(field);
    setReviewDetails({ ...reviewDetails });
    setReviewError({ ...reviewError });
    // form[field] = value;
    // setReviewDetails({ ...form });
  };
  const checkValidation = (field) => {
    let errorMessage = "";
    const { Title, Review } = reviewDetails;
    if (field === "Title" && Title?.length === 0) {
      errorMessage = "Title is required";
    }
    if (field === "Review" && Review?.length === 0) {
      errorMessage = "Review is required";
    }
    return errorMessage;
  };
  const validationForm = () => {
    let errors = {};
    const { Title, Review, Rating } = reviewDetails;

    if (Title?.length === 0) {
      errors["Title"] = "Title is required";
    }
    if (Review?.length === 0) {
      errors["Review"] = "Review is required";
    }
    if (Rating === 0) {
      errors["Rating"] = "Please select rating";
    }
    for (var key in error) {
      if (error[key] !== "") {
        break;
      }
    }

    setReviewError({ ...errors });
    return !Object.keys(errors)?.length > 0;
  };
  const handleReviewSubmit = async () => {
    if (validationForm()) {
      try {
        const apiUrl = Product_Rating;
        let RegisterId = fetchRegisterId();
        let ProductId = productId;
        let payload = {
          RegisterId,
          ProductId,
          ...reviewDetails,
        };

        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams(payload).toString(),
        });
        if (!response.ok) {
          throw new Error(
            `Failed to fetch product details! Status: ${response.status}`
          );
        }
        const data = await response.json();
        if (data.Status) {
          toast.success(data.StatusMessage);
        } else {
          toast.error(data.StatusMessage);
        }
      } catch (err) {
        setError(err.message);
        //debugger;
      } finally {
        fetchData();
        setLoading(false);
        setIsAddReview(false);
        setReviewDetails({
          Rating: 0,
          Review: "",
          Title: "",
        });
      }
    }
  };

  const userData = fetchUserData()

  return (
    <div className="product__container__item">
      <div className="pdp_review">
        <div className="page-width">
          <div className="pdp_review_wrap">
            <div className="review_left">
              <div className="star_count_wrap">
                
                <div className="star_bars">
                  <ul>
                    <li>
                      <span>5</span>
                      <img src={y_star} alt="star" />
                      <div className="star_bar five_star"></div>
                    </li>
                    <li>
                      <span>4</span>
                      <img src={y_star} alt="star" />
                      <div className="star_bar four_star"></div>
                    </li>
                    <li>
                      <span>3</span>
                      <img src={y_star} alt="star" />
                      <div className="star_bar three_star"></div>
                    </li>
                    <li>
                      <span>2</span>
                      <img src={y_star} alt="star" />
                      <div className="star_bar two_star"></div>
                    </li>
                    <li>
                      <span>1</span>
                      <img src={y_star} alt="star" />
                      <div className="star_bar one_star"></div>
                    </li>
                  </ul>
                </div>
                <div className="total_rating">
                  <h3>{Total}</h3>
                  <div className="total_star_count">
                    {Array?.from({ length: 5 }).map((stars, index) => {
                      return (
                        <img
                          key={index}
                          src={index < Math.floor(Total) ? y_star : g_star}
                          alt={`Star ${index}`}
                        />
                      );
                    })}
                  </div>
                  <h4>{TotalReview} Reviews</h4>
                </div>
              </div>
              {userData &&
                <div className="add_review">
                  <h5>Write Your Product Review</h5>
                  <p>
                    if you’ve used this product, share your thoughts with other
                    customers
                  </p>
                  <button className="btn_primary" onClick={toggleAddReviewPopup}>
                    Write a Review
                  </button>
                </div>
              }
            </div>

            <div className="review_right">
              <div className="review_list">
                {reviews.map((review) => {
                  return (
                    <div key={review.Created} className="review_list_item">
                      <div className="review_icon">
                        <div className="rv_icon_img">
                          <img
                            src={review.UserImage}
                            alt={`Star ${review.Created}`}
                            style={{ objectFit: "contain" }}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = logo_orange;
                            }}
                          />
                        </div>
                        <div className="rv_auther">
                          <h6>{review.Name}</h6>
                          <div className="rv_auther_star">
                            {Array?.from({ length: 5 }).map((stars, index) => {
                              return (
                                <img
                                  key={index}
                                  src={index < +review.Rating ? y_star : g_star}
                                  alt={`Star ${index}`}
                                />
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="review_content">
                        <p>{review.Review}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
              {/* <button className="btn_primary btn_border_btn">
                Write a Review
              </button> */}
            </div>
          </div>
        </div>
      </div>

      <div
        className="popup_main"
        style={{ display: isAddReview ? "flex" : "none" }}
      >
        <div className="popup_overlay"></div>
        <div className="popup_wraper">
          <div className="pop_up_title">
            <div className="cs_pop_close" onClick={toggleAddReviewPopup}>
              <img src={cross_black}  alt={`Star`} />
            </div>
          </div>
          <div className="popup_body_wrap">
            <div className="review_pop_body">
              <h5>Rate Your Purchase Experience</h5>
              <p>Share your experience to help others</p>

              <div className="review_form">
                <div className="pdp_rv_stars">
                  {Array?.from({ length: 5 }).map((stars, index) => {
                    return (
                      <img
                        key={index}
                        onClick={() => handleReviewChange(index + 1)}
                        src={index < reviewDetails.Rating ? Star_icon : g_star}
                        alt={`Star ${index}`}
                      />
                    );
                  })}
                  {reviewError?.Rating && (
                    <p className="text-red-600 mt-1">{reviewError?.Rating}</p>
                  )}
                </div>
                <div className="input_field">
                  <label>Enter Title</label>
                  <input
                    type="text"
                    onChange={(e) => {
                      onReviewChange("Title", e.target.value.trimStart());
                    }}
                    value={reviewDetails?.Title}
                  />
                  {reviewError?.Title && (
                    <p className="text-red-600 mt-1">{reviewError?.Title}</p>
                  )}
                </div>
                <div className="input_field">
                  <label>Message</label>
                  <textarea
                    rows="4"
                    onChange={(e) => {
                      onReviewChange("Review", e.target.value.trimStart());
                    }}
                    value={reviewDetails?.Review}
                  ></textarea>
                  {reviewError?.Review && (
                    <p className="text-red-600 mt-1">{reviewError?.Review}</p>
                  )}
                </div>
                <div className="submit_review">
                  <button className="btn_primary" onClick={handleReviewSubmit}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewDetail;
