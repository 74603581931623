// src/MetaPixel.js
import React, { useEffect } from "react";

const MetaPixel = () => {
  useEffect(() => {
    const loadFacebookPixel = async () => {
      try {
        const module = await import('react-facebook-pixel');
        const ReactPixel = module.default;
        
        // Initialize the Facebook Pixel with your Pixel ID
        ReactPixel.init('305121759293290'); // Replace with your Pixel ID
        ReactPixel.pageView(); // Track initial page view
      } catch (err) {
        console.error("Failed to load Facebook Pixel:", err);
      }
    };

    loadFacebookPixel();
  }, []);

  return null; 
};

// Function to track page views
export const trackPageView = () => {
	import('react-facebook-pixel')
	  .then(module => {
		const ReactPixel = module.default;
		ReactPixel.pageView(); // Track the page view
	  })
	  .catch(err => {
		console.error("Failed to load Facebook Pixel for tracking page view:", err);
	  });
  };
  

  

export default MetaPixel;
