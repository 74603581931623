import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import RemoveButton from "../../common/removeButton";
import PlusIcon from "../../../assests/icons/plus.svg";
import MinusIcon from "../../../assests/icons/minus.svg";
import { PRODUCTDETAIL } from "../../../Constants";
import {
  WEB_CARTLIST,
  WEB_CART_DELETE,
  WEB_CART_QUANTITY_UPDATE,
  fetchRegisterId,
} from "../../../Constants";
import { toast } from "react-toastify";
import moengage from "@moengage/web-sdk";
import urlReplace from "../../../services/urlReplace";

const CartItem = ({
  onItemRemove = () => {},
  onUpdate = () => {},
  setCountItem,
  countItems,
  setItems,
  USIN,
  setCountItems,
  appliedCoupon,
}) => {
  const [sessionError, setSessionError] = useState(null);
  const [toastShown, setToastShown] = useState(false);

  useEffect(() => {
    fetchCartItems();
  }, []);

  //console.log(USIN,"USIN")

  const fetchCartItems = async () => {
    try {
      let response;
      let registerId = fetchRegisterId();
      if (registerId) {
        response = await fetch(WEB_CARTLIST, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            RegisterId: registerId,
            PaymentType: "Prepaid",
            ApplicationType: "web",
            CouponApplyCode: appliedCoupon,
          }),
        });
      }

      if (!response.ok) {
        throw new Error(
          `Failed to fetch cart items! Status: ${response.status}`
        );
      }

      const cartItemsData = await response.json();

      setItems(cartItemsData || []);
      setCountItem(cartItemsData.Result);
      setCountItems(cartItemsData);
      //console.log("testing view cart",cartItemsData)
      trackViewItemDataLayer(cartItemsData); //sending random  just to render the useEffect function which will automatic render the count .No dependency on what value is being passed
    } catch (error) {
      setSessionError(error.message);
    }
  };

  const handleQuantityChange = async (newQuantity, itemId, item) => {
    try {
      const response = await fetch(WEB_CART_QUANTITY_UPDATE, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          RegisterId: fetchRegisterId(),
          ProductId: item.ProductId,
          Quantity: newQuantity,
          CartListId: itemId,
          ApplicationType: "web",
        }),
      });

      const result = await response.json();
      //console.log(result)

      if (result.Status) {
        toast.success(result.StatusMessage);
      } else {
        if (result.Toast) {
          toast(result.Toast);
        } else {
          toast.error(result.StatusMessage);
        }
      }

      if (!response.ok) {
        throw new Error(
          `Failed to update quantity! Status: ${response.status}`
        );
      }

      moengage.track_event(`Update_Add_to_Cart`, {
        ID: `+91${JSON.parse(Cookies.get("Otp")).MobileNo}`,
        "Product ID": item.ProductId,
        "Product Name": item.PorductName,
        "Base Price": item.PriceQuantity[0].Mrp,
        "Promotion Price": item.PriceQuantity[0].Price,
        "Discount Percentage": parseInt(
          item.Note.replace("(", "")
            .replace(")", "")
            .replace("%", "")
            .split(" ")[0]
        ),
        [item.variant1_name ? item.variant1_name : ""]: item.variant1_value,
        ...(item.variant2_name !== "" && {
          [item.variant2_name]: item.variant2_value,
        }),
        USIN: item.USIN ? item.USIN : "",
        Quantity: newQuantity,
        "Screen Name": "Cart Page ",
      });
      await fetchCartItems();
    } catch (error) {
      console.error("Error updating quantity or fetching cart list:", error);
    }
  };

  const handleRemoveClick = async (cartListId, item, cartItemsData) => {
    try {
      const response = await fetch(WEB_CART_DELETE, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          CartListId: cartListId,
        }),
      });

      if (!response.ok) {
        throw new Error(
          `Failed to remove cart item! Status: ${response.status}`
        );
      }

      const updatedItems = countItems.filter((item) => item.id !== cartListId);
      setCountItem(updatedItems);
      // Remove from Cart DataLayer //
      trackRemoveFromCartDataLayer(cartItemsData);

      if (typeof onItemRemove === "function") {
        onItemRemove(updatedItems.length);
      }
      if (typeof onUpdate === "function") {
        onUpdate(updatedItems);
      }
      moengage.track_event(`Remove_From_Cart`, {
        ID: `+91${JSON.parse(Cookies.get("Otp")).MobileNo}`,
        Quantity: item.ProductQuantity,
        "Product ID": item.ProductId,
        "Product Name": item.PorductName,
        "Base Price": item.PriceQuantity[0].Mrp,
        "Promotion Price": item.PriceQuantity[0].Price,
        "Discount Percentage": parseInt(
          item.Note.replace("(", "")
            .replace(")", "")
            .replace("%", "")
            .split(" ")[0]
        ),
        [item.variant1_name ? item.variant1_name : ""]: item.variant1_value,
        ...(item.variant2_name !== "" && {
          [item.variant2_name]: item.variant2_value,
        }),
        USIN: item.USIN ? item.USIN : "",
        "Screen Name": "Cart Page ",
      });
      fetchCartItems();
    } catch (error) {
      setSessionError(error.message);
    }
  };

  const incrementQuantity = async (itemId, itemD) => {
    const item = countItems.find((item) => item.id === itemId);
    if (item) {
      const newQuantity = parseInt(item.ProductQuantity, 10) + 1;
      if (newQuantity > 4) {
        if (!toastShown) {
          toast("Cannot add more than 4 Quantity");
          setToastShown(true);
        }
      } else {
        // setAppliedCoupon("")
        await handleQuantityChange(newQuantity, itemId, itemD);
      }
    }
  };

  const decrementQuantity = async (itemId, itemD, cartItemsData) => {
    const item = countItems.find((item) => item.id === itemId);

    if (item && item.ProductQuantity > 1) {
      const newQuantity = parseInt(item.ProductQuantity, 10) - 1;
      await handleQuantityChange(newQuantity, itemId, itemD);
      trackRemoveFromCartDataLayer(cartItemsData);
    }
  };

  const calculateTotalPrice = () => {
    return countItems.reduce(
      (total, item) => total + item.TotalDiscountPrice * item.ProductQuantity,
      0
    );
  };

  if (sessionError) {
    return <div>Error: {sessionError}</div>;
  }

  const fixUrlString = (str) => {
    if (typeof str !== "string") {
      return ""; // or handle it in another wayesimi
    }
    return urlReplace(str);
  };

  let handleNewTab = (item) => {
    let productNewTabUrl = `${window.location.origin}/product/${
      item?.USIN
    }/${fixUrlString(item?.PorductName)}/${fixUrlString(
      item?.variant1_value
    )}/${fixUrlString(item?.variant2_value)}?productid=${item.ProductId}`;
    window.open(productNewTabUrl, "_blank");
  };

  // Data layer Product View Cart Event
  const trackViewItemDataLayer = (cartItemsData) => {
    // const items = cartItemsData?.Result.map((item, index) => ({
    //   item_id: item.USIN,
    //   item_name: item.PorductName,
    //   affiliation: "UnfitMart",
    //   coupon: "SUMMER_FUN",
    //   // discount: item.PriceQuantity[0]?.Note.replace(/[()]/g, ""),
    //   index: index,
    //   item_brand: item.BrandName,
    //   item_category: item.CategoryName,
    //   item_category2: item.SubCategoryName,
    //   item_list_id: "cart_page",
    //   item_list_name: "Cart Page",
    //   item_variant_flavour: item.variant1_value,
    //   item_variant_size: item.variant2_value,
    //   price: item.PriceQuantity[0].Price,
    //   quantity: item.ProductQuantity
    // }));

    // Calculate the total cart value based on item prices and quantities
    const items = cartItemsData?.Result.map((item, index) => ({
      item_id: item.USIN || "SKU_12345",
      item_name: item.PorductName || "Stan and Friends Tee",
      affiliation: "UnfitMart",
      coupon: "SUMMER_FUN",
      discount: item.PriceQuantity[0]?.Note
        ? parseFloat(item.PriceQuantity[0].Note.replace(/[()]/g, ""))
        : 0,
      index: index,
      item_brand: item.BrandName || "Google",
      item_category: item.CategoryName || "Apparel",
      item_category2: item.SubCategoryName || "Adult",
      item_list_id: "cart_page",
      item_list_name: "Cart Page",
      Flavour: item.variant1_value || "green",
      Size: item.variant2_value || "green",
      location_id: item.locationId || "ChIJIQBpAG2ahYAR_6128GcTUEo",
      price: item.PriceQuantity[0].Price || 10.01,
      quantity: item.ProductQuantity || 1,
    }));
    const totalValue = items.reduce(
      (sum, item) => sum + item.price * item.quantity,
      0
    );

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "view_cart", // Changed event name to 'view_cart'
      ecommerce: {
        currency: "INR",
        value: totalValue, // Dynamic total value calculation
        items: items,
      },
    });
  };

  // Data layer Product Remove from Cart Event
  const trackRemoveFromCartDataLayer = (cartItemsData) => {
    const items = cartItemsData?.Result.map((item, index) => ({
      item_id: item.USIN || "SKU_12345",
      item_name: item.PorductName || "Stan and Friends Tee",
      affiliation: "UnfitMart",
      coupon: "SUMMER_FUN",
      discount: item.PriceQuantity[0]?.Note
        ? parseFloat(item.PriceQuantity[0].Note.replace(/[()]/g, ""))
        : 0,
      index: index,
      item_brand: item.BrandName || "Google",
      item_category: item.CategoryName || "Apparel",
      item_category2: item.SubCategoryName || "Adult",
      item_list_id: "cart_page",
      item_list_name: "Cart Page",
      item_variant: item.variant1_value || "green",
      location_id: item.locationId || "ChIJIQBpAG2ahYAR_6128GcTUEo",
      price: item.PriceQuantity[0].Price || 10.01,
      quantity: item.ProductQuantity || 1,
    }));

    // Calculate the total value of the items being removed
    const totalValue = items?.reduce(
      (sum, item) => sum + item.price * item.quantity,
      0
    );

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "remove_from_cart", // Changed event name to 'remove_from_cart'
      ecommerce: {
        currency: "INR",
        value: totalValue,
        items: items,
      },
    });
  };

  return (
    <div className="cart__item__containers">
      {countItems?.map((item) => (
        <div
          key={item.id}
          className="cart__item__content"
          data-line-item={item.id}
        >
          {item.variant1_value === item.variant1_value &&
            item.variant2_value === item.variant2_value && (
              <div className="cart__item__container flex">
                <div className="cart__item__image">
                  {item.Image && item.Image[0] && (
                    <img src={item.Image[0].ImageUrl} alt="cart-item" />
                  )}
                </div>
                <div className="cart__item__contents">
                  <div
                    className="cart__item__title"
                    onClick={() => handleNewTab(item)}
                  >
                    {item.PorductName}
                  </div>
                  <div className="cart__item__price flex gap5">
                    <div className="actual_price">₹{item.TotalPrice}</div>
                    {item.PriceQuantity &&
                      item.PriceQuantity[0].Mrp != item.TotalPrice && (
                        <div className="compare_price">
                          <s>₹{item.PriceQuantity[0].Mrp}</s>
                        </div>
                      )}
                    {item.Note &&
                      item.PriceQuantity[0].Mrp != item.TotalPrice && (
                        <div className="cart__item__discounts green">
                          <span>{item.Note}</span>
                        </div>
                      )}
                  </div>
                  <div className="cart__item__variant grey">
                    {item.variant1_name && (
                      <p>
                        {" "}
                        {item.variant1_name} : {item.variant1_value}{" "}
                      </p>
                    )}
                    {item.variant2_name && (
                      <p>
                        {" "}
                        {item.variant2_name} : {item.variant2_value}{" "}
                      </p>
                    )}
                  </div>
                  <div className="cart__item__buttons flex gap10">
                    <div className="cart__quantity">
                      <div
                        className="quantity__selector"
                        data-product={item.ProductId}
                      >
                        <div
                          className="plus__btn"
                          onClick={() => incrementQuantity(item.id, item)}
                          disabled={item.ProductQuantity >= 4}
                        >
                          <img src={PlusIcon} alt="plus" />
                        </div>
                        <div className="input__value">
                          {item.ProductQuantity}
                        </div>
                        <div
                          className="minus__btn"
                          onClick={() => decrementQuantity(item.id, item)}
                          disabled={item.ProductQuantity <= 1}
                        >
                          <img src={MinusIcon} alt="minus" />
                        </div>
                      </div>
                    </div>
                    <div
                      className="cart__remove"
                      onClick={() => handleRemoveClick(item.id, item)}
                    >
                      <RemoveButton />
                    </div>
                  </div>
                </div>
              </div>
            )}
        </div>
      ))}
    </div>
  );
};

export default CartItem;
