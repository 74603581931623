import React from 'react'

const VariantButtons = ({product,variantsArray,handleVariant1Change,usinVariant1}) =>{


  return (

	    <>
                      <label className="option__name">Flavour</label>

                            <div className="size__option__item" >
                              {product.Result[0].GroupVariantDetails.map(
                                (variantItem, index) => (
                                  <div
                                    key={index}
                                    className={`option__value ${
										usinVariant1 ===
                                        variantItem.Variant1Value ||
                                      (!usinVariant1 && index === 0)
                                        ? "active orange"
                                        : ""
                                    }
									
									
									
									`}
                                    onClick={() => handleVariant1Change(variantItem.Variant1Value)}
                                  >
                                    {variantItem.Variant1Value}
                                  </div>
                                )
                              )}
                            </div>
                          
                      
                    </>

  )
}

export default VariantButtons
