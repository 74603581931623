
import { BRAND_LIST } from "../../../Constants";
import { PARENT_CATEGORY_AND_SUBCATEGORYLIST } from "../../../Constants";



// src/services/brandService.js
let cachedBrands = null;
let isFetching = false;


const fetchBrandsFromApi = async () => {
  try {

    const response = await fetch(BRAND_LIST, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        // Add any other headers if required
      },
      // body: payload // Uncomment and adjust if you need to send data with POST
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const brandsData = await response.json();

    // Ensure brandsData.Result is valid and contains the expected structure
    if (!brandsData || !brandsData.Result || !Array.isArray(brandsData.Result)) {
      throw new Error('Invalid response format from BrandList API');
    }

    // Transform data as needed, assuming brandsData.Result is an array of objects
    const brands = brandsData.Result.reduce((acc, brand) => {
      const firstLetter = brand.BrandName.charAt(0).toUpperCase();
      if (!acc[firstLetter]) {
        acc[firstLetter] = [];
      }
      acc[firstLetter].push({
        name: brand.BrandName,
        image: brand.BrandImage,
        id: brand.BrandId,
        logo: brand.BrandLogo,
        description: brand.BrandDescription
      });
      return acc;
    }, {});

    // Sort brands alphabetically
    const sortedBrands = {};
    Object.keys(brands)
      .sort()
      .forEach(key => {
        sortedBrands[key] = brands[key].sort((a, b) => a.name.localeCompare(b.name));
      });

    // Cache brands for future calls
    cachedBrands = sortedBrands;
    isFetching = false; // Reset fetching flag

    return sortedBrands;

  } catch (error) {
    console.error('Error fetching brands:', error);
    throw new Error('Error fetching brands');
  }
};


const CategoryList = async () => {
  try {
    if (cachedBrands) {
      return cachedBrands; // Return cached brands if available
    }

    if (!isFetching) {
      isFetching = true; // Set fetching flag to prevent concurrent requests
      return await fetchBrandsFromApi();
    }

    // If already fetching, return a promise that resolves when fetching completes
    return new Promise((resolve, reject) => {
      const checkAndResolve = () => {
        if (!isFetching && cachedBrands) {
          resolve(cachedBrands);
        } else {
          setTimeout(checkAndResolve, 100); // Check again after a short delay
        }
      };
      checkAndResolve();
    });

  } catch (error) {
    console.error('Error fetching brands:', error);
    throw new Error('Error fetching brands');
  }
};



const fetchCategoryFromApi = async () => {
  const payload = {
    groupCategoryId: 0,
    LevelType: 'shopDashboard',
    ApplicationType: "web"
  };

  try {
    const response = await fetch(PARENT_CATEGORY_AND_SUBCATEGORYLIST, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams(payload).toString(),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;

  } catch (error) {
    console.error('Error fetching data:', error);
    throw new Error('Error fetching data'); // Rethrow error for handling at the call site
  }
};

export { fetchCategoryFromApi };


