import React, { useEffect, useState } from "react";
import { ReactComponent as Line } from "../../assests/icons/line.svg";

function StoreTimings({ StoreHours }) {
  //console.log(StoreHours)

  const handleChange = (day, FromTime, ToTime) => {

	if( FromTime !== "00:00" && ToTime!== "00:00")
   { setSelectedDay(day);
    setStoreTimings(FromTime + " to " + ToTime);}
	else{
		setSelectedDay(day);
    setStoreTimings("Closed")
	}
    //console.log(day, FromTime, ToTime);
  };

  const today = new Date();
  const dayIndex = today.getDay();
  const dayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const todayDay = dayNames[dayIndex];
  const timing = StoreHours.filter((time) => time.Day === todayDay);
  //console.log(timing);

  const [storeTimings, setStoreTimings] = useState(null);
  const [selectedDay, setSelectedDay] = useState(todayDay);

  useEffect(() => {
    handleChange(todayDay, timing[0].FromTime, timing[0].ToTime);
  }, []);

  return (
    <div className="store-timing">
      <div className="store-open">
      <span>{storeTimings === "Closed" ? "Closed" : "Open Now"}</span>
      </div>
      <h3>Store Timing</h3>
	  {storeTimings === "Closed" ? "--" : ( <p>
        Timing:{" "}
        {storeTimings
          ? storeTimings
          : StoreHours[0]?.FromTime + " To " + StoreHours[0]?.ToTime}
      </p>) }
      
      <div className="blog__post__line">
        <Line />
      </div>
      <form>
        {StoreHours &&
          StoreHours.map((day,index) => (
            <label key={index}>
              <input
                type="radio"
                name="day"
                value={day.Day}
                id={day.Day}
                onChange={() => handleChange(day.Day, day.FromTime, day.ToTime)}
                checked={selectedDay === day.Day}
              />
              {day?.Day}
            </label>
          ))}
      </form>
    </div>
  );
}

export default StoreTimings;
