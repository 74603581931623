import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ImageComponent from '../../image/mainImageComp';
import Loader from '../../loader/loadingCard';
import moengage from "@moengage/web-sdk";
import Cookies from "js-cookie";
// import { PRODUCTDETAIL } from "../../../../Constants";
import { useMediaQuery } from 'react-responsive';
import { fetchBannerData } from '../../../../api/HomePageApi';
// import { ReactComponent as LeftArrow } from '../../../../assests/icons/LeftArrow.svg';
import { Link } from "react-router-dom";
import { replace } from "lodash";
import urlReplace from "../../../../services/urlReplace";

const DefaultSliderImages = [
  {
    mobileSrc: '/images/mobile-banner.png',
    desktopSrc: '/images/banner1.png',
    alt: 'New Image 1',
    productId: '',
    Variant1Value: '',
    Variant2Value: ''
  },
  {
    mobileSrc: '/images/mobile-banner.png',
    desktopSrc: '/images/banner1.png',
    alt: 'New Image 2',
    productId: '',
    Variant1Value: '',
    Variant2Value: ''
  },
  {
    mobileSrc: '/images/mobile-banner.png',
    desktopSrc: '/images/banner1.png',
    alt: 'New Image 3',
    productId: '',
    Variant1Value: '',
    Variant2Value: ''
  },
  {
    mobileSrc: '/images/mobile-banner.png',
    desktopSrc: '/images/banner1.png',
    alt: 'New Image 4',
    productId: '',
    Variant1Value: '',
    Variant2Value: ''
  },
  {
    mobileSrc: '/images/mobile-banner.png',
    desktopSrc: '/images/banner1.png',
    alt: 'New Image 5',
    productId: '',
    Variant1Value: '',
    Variant2Value: ''
  }
];
let banners;
const OfferBannerCard = ({data,name}) => {
  const [sliderImages, setSliderImages] = useState(DefaultSliderImages);
  const [isLoading, setIsLoading] = useState(true);
  //const [dailyDeals, setDailyDeals] = useState([]);

  useEffect(() => {
    let isMounted = true; // track if component is mounted
    const loadBannerData = async () => {
      try {
        // const data = await fetchBannerData();
        //  banners = data?.Advertisement?.DailyDeals || [];
       // setDailyDeals(data);
       banners= data;
        if (banners.length > 0) {
          const formattedImages = banners.map(banner => ({
            mobileSrc: banner.Banner?.trim() || '/images/default-mobile.png',
            desktopSrc: banner.Banner?.trim() || '/images/default-desktop.png',
            name:banner.ProductName || "undefined",
            productId: banner.ProductId || "",
            Variant1Value: banner.Variant1Value || "",
            Variant2Value: banner.Variant2Value || "",
            alt: banner.alt || 'Default Image',
            name: banner.ProductName,

            USIN:banner.USIN || "undefined",
       
          }));
          if (isMounted) {
            setSliderImages(formattedImages);
            setIsLoading(false);
          }
        }
      } catch (error) {
        console.error('Error fetching banner data:', error);
        if (isMounted) {
          setSliderImages(DefaultSliderImages);
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    loadBannerData();

    return () => {
      isMounted = false; // cleanup function to set isMounted to false when the component unmounts
    };
  }, []); // empty dependency array to run only once on mount


  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const sliderSettings = {
    dots: false,
    arrows: true,
    rtl: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2.45,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    touchThreshold: 100,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.45,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2.45,
          slidesToScroll: 1,
          dots:false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots:true
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        }
      }
    ]
  };
//console.log(sliderImages)

const handleMoenagege = (image) => {
  //console.log(image)


    moengage.track_event("Homepage_Daily_Deal_Viewed", {
        "ID": (Cookies.get("Otp")) ? `+91${JSON.parse(Cookies.get("Otp")).MobileNo}` :"", 
        "Brand Name" : image.BrandName,
        "Product Name" :  image.ProductName,
        "Product Price" : image.ListingPrice,
        "Discount Percentage" :parseInt( image.Note.replace('(',"").replace(')',"").replace('%',"").split(" ")[0]),
        [image.Variant1Name ? image.Variant1Name :""] :image.Variant1Value,
    ...(image.Variant2Name !== "" && {  [ image.Variant2Name ] : image.Variant2Value }),
    "USIN": image.USIN ? image.USIN :"" ,
        "Screen Name" : name,
        
       });
  
}




  const fixUrlString = (str) => {
    if (typeof str !== 'string') {
      return ''; // or handle it in another way
    }
    return urlReplace(str)
  };

  //console.log(sliderImages,"sliderImages")

  return (
    <div> {banners &&  (
      <div className="offer__banner__wrapper section_space">
      <div className="offer__banner__container amazing__brand page-width">
        <div className="hm__prod__slider-title less_space_title">
          <h2>{name}</h2>
          {/* <div className="hm__prod__mobile__arrow"><LeftArrow /></div> */}
        </div>
      </div>
      <div className="banner_sl_container">
        {isLoading ? (
          <div className="loading-spinner">
            <Loader />
          </div>
        ) : (
          <Slider {...sliderSettings} className="cm_banner_slider cm_arrow_side">
   
           {sliderImages.map((image, index) => {
            const productName = image.name ? `${image.name}/` : ''; 
            return (
              <div key={index} onClick={() => handleMoenagege(data[index])}>
                {image.productId && (
                  <Link
                  to={`/product/${image.USIN || "undefined"}/${fixUrlString(productName)}${fixUrlString(image?.Variant1Value) ? `/${fixUrlString(image?.Variant1Value)}` : '/'}${fixUrlString(image?.Variant2Value) ? `/${fixUrlString(image?.Variant2Value)}` : '/'}?productid=${image.productId}`}
                  >
                    <ImageComponent
                      src={isMobile ? image.mobileSrc : image.desktopSrc}
                      alt={image.alt}
                      height={'320px'}
                      pageWidth={isMobile ? "100%" : "1200px"}
                    />
                  </Link>
                )}
              </div>
            );
          })}

          </Slider>
        )}
      </div>
    </div>)}</div>
  );
}

export default OfferBannerCard;
