const isoFormat = (date, time) => {
  //     const date = "09 September 2024";
  // const time = "04:41:22 PM";

  // Split the date string into day, month, and year
  const [day, month, year] = date.split(" ");

  // Create a new Date object
  const dateObject = new Date(year, monthToNumber(month), day);

  // Split the time string into hours, minutes, and seconds
  const [hours, minutes, second] = time.split(":");
  const [seconds, amPm] = second.split(" ");
  const isPM = amPm === "PM";

  // Set the time
  dateObject.setHours(isPM ? parseInt(hours) + 12 : parseInt(hours));
  dateObject.setMinutes(minutes);
  dateObject.setSeconds(seconds);

  // Convert the date to ISO 8601 format
  const isoDate = dateObject.toISOString().replace(/\.\d{3}Z$/, "Z");
  //console.log(dateObject)
  //console.log(isoDate); // Output: 2024-09-09T16:41:22.000Z

  // Helper function to convert month name to number
  function monthToNumber(month) {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return monthNames.indexOf(month);
  }

  return isoDate;
};
export default isoFormat;
