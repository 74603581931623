import { UNFIT_STORE_MARKER_LIST,UNFIT_STORE_LIST,UNFIT_STORE_DETAILS } from "../../Constants";

export const fetchMarkerList = async () => {

  const response = await fetch(UNFIT_STORE_MARKER_LIST, {
	method: "GET",
	headers: {
	  "Content-Type": "application/x-www-form-urlencoded",
	},
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();

  return data.Result|| [];
};

export const fetchStoreList = async (city) => {

	const response = await fetch(UNFIT_STORE_LIST, {
		method: "POST",
	headers: { "Content-Type": "application/json" },
	body: JSON.stringify({
	 City: city === "All" ? '' : city
	
	  
	}),
	});
  
	if (!response.ok) {
	  throw new Error(`HTTP error! status: ${response.status}`);
	}
  
	const data = await response.json();
  
	return data.Result|| [];
  };


  export const fetchStoreDetails = async (id) => {

	const response = await fetch(UNFIT_STORE_DETAILS, {
		method: "POST",
	headers: { "Content-Type": "application/json" },
	body: JSON.stringify({
		StoreId:id
	
	  
	}),
	});
  
	if (!response.ok) {
	  throw new Error(`HTTP error! status: ${response.status}`);
	}
  
	const data = await response.json();
  
	return data.Result|| [];
  };