import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchRedirectionUrl } from './fetchRedirectionUrl';
import urlReplace from '../services/urlReplace';

const  RedirectionPage = () => {


	//console.log("Reached redirection")

	const [USIN,setUSIN] = useState();
	const [pid,setPid] = useState(null);
	const [v1,setV1] = useState(null);
	const [v2,setV2] = useState(null);
	const  navigate = useNavigate()
	let url = window.location.href;
	const segments = url.split("/");
    const { search } = useLocation();
  
	// Extract the flavor and size from the appropriate segments
	const queryParams = new URLSearchParams(search);


useEffect(() => {

	
	// Retrieve and decode query parameters
	const ProductId = queryParams.get("productid") || segments[5];
	const variant1Value = queryParams.get('variant1Value');
	setPid(ProductId);
	
	if(!variant1Value && segments.length >4 ){
		
		const flavor = decodeURIComponent(segments[segments.length - 2]);
		const size = decodeURIComponent(segments[segments.length - 1].split("?")[0]);
	  
		let Variant1 = flavor
		  ?.replace(/-/g, " ")
		  ?.replace(/\b\w/g, (char) => char.toUpperCase());
		let Variant2 = size
		  ?.replace(/-/g, " ")
		  ?.replace(/\b\w/g, (char) => char.toUpperCase());
	  
		let Variant1Val =  Variant1?.replace(/\+/g, " ") ;
		let Variant2Val =  Variant2?.replace(/\+/g, " ") 
	  //console.log(Variant1Val,Variant2Val);
	  setV1(Variant1Val);
	  setV2(Variant2Val)
	}else{
		const variant2Value = queryParams.get('variant2Value');
		setV1(variant1Value);
		setV2(variant2Value)
		//console.log(ProductId,variant1Value,variant2Value)
	}
	  
	 
	 

},[search])



  let response
  const loadCity = async () => {
    //setLoading(true);
    try {
		//console.log("response : ",pid,v1,v2)
		response = await fetchRedirectionUrl(pid,v1,v2);
		let res= response.Result
//debugger
	
		switch (response.StatusType) {
			case 1 :
				
					setUSIN(res.USIN);
				
					handleNewUrl(res.USIN,res.ProductName,res.Variant1Value,res.Variant2Value)
				
			
		
			break;
			case 2 :
				handleNewUrl(res.USIN,res.ProductName,res.Variant1Value,res.Variant2Value)
			break;
			case 3 :
				handleBrandRedirectionUrl(res.BrandName,res.BrandId)
			break;
			case 4 :
			setUSIN(res.USIN);
			break;
			case 0 :
				handleEmptyResponse();
			break;
			default:
				handleEmptyResponse();


		}

	
	 

	//   if(Object.keys(response).length == 1){   //FETCHING USIN
		
	//   }
	//   else if(Object.keys(response).length == 0){ //NULL RESPONSE
	// 	handleEmptyResponse()
	//   }else if(Object.keys(response).length > 1){
	// 	handleNewUrl(response.USIN,response.Variant1Value,response.Variant2Value)   //FOUND PRODUCT
	//   }
	//   else if(Object.keys(response).length > 0){
	// 	handleBrandRedirectionUrl(response.BrandName,response.BrandId)   //FOUND PRODUCT
	//   }

    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
     // setLoading(false); // Ensure loading is false after data fetch or error
    }
  };

  useEffect(() => {
	//console.log("response : ",pid,v1,v2)
	if(pid !== null)
    {loadCity();}

  },[pid])
  const fixUrlString = (str) => {
    if (typeof str !== 'string') {
      return ''; // or handle it in another way
    }
    return urlReplace(str)
  };




  const handleEmptyResponse = () => {
	//console.log(url.split('/product')[0]+"/brands")
	window.location.href = url.split('/product')[0]+"/brands"
  };
 
  const handleNewUrl = (USIN,PNAME,V1,V2) => {
	//debugger
	let newUrl = `/product/${USIN}/${fixUrlString(PNAME)}/${fixUrlString(V1)}/${fixUrlString(V2)}?productid=${pid}`
	
	//console.log(url.split('/product')[0]+newUrl)
	window.location.href = url.split('/product')[0]+ newUrl

  };

  const handleBrandRedirectionUrl = (BrandName,BrandId) => {

	//console.log(`/brands/${BrandName.replace(/[^a-zA-Z0-9 ]/g, "")
	// 	.replace(/\s+/g, "-")
	// 	.toLowerCase()}?id=${BrandId}`)
		window.location.href = url.split('/product')[0]  +`/brands/${BrandName.replace(/[^a-zA-Z0-9 ]/g, "")
		.replace(/\s+/g, "-")
		.toLowerCase()}?id=${BrandId}`
  }


//   useEffect(() => {
// 	//console.log(USIN)
// 	if(USIN){
// 		handleRedirectWithReload()
// 	}
//   },[USIN])

  return (
	<div>
	  
	</div>
  )
}

export default RedirectionPage
