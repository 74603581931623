import { REDIRECTION_URL } from "../Constants";

export const fetchRedirectionUrl = async (ProductId,Variant1Val,Variant2Val) => {

	const response = await fetch(REDIRECTION_URL, {
		method: "POST",
	headers: { "Content-Type": "application/json" },
	body: JSON.stringify({
		ProductId:ProductId,
		Variant1Value: Variant1Val,
        Variant2Value: Variant2Val
	  
	}),
	});
  
	// if (!response.ok) {
	//   throw new Error(`HTTP error! status: ${response.status}`);
	// }
  
	const data = await response.json();
  
	return data|| [];
  };