import { toast } from 'react-toastify';
import { fetchRegisterId, ADD_TO_CART } from "../Constants";

const addProductToCartApi = async (productId, qty, V1, V2, USIN) => {
  try {
    let RegisterId = fetchRegisterId();

    const response = await fetch(ADD_TO_CART, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ProductId: productId || "",
        RegisterId,
        ProductQuantity: qty,
        variant1_value: V1,
        variant2_value: V2,
        USIN: USIN,
        ApplicationType: "web",
      }),
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch cart items! Status: ${response.status}`);
    }

    const result = await response.json();
    
    if (result.Status) {
      toast.success(result.StatusMessage);
    } else {
      if (result.Toast) {
        toast(result.Toast);
      } else {
        toast.error(result.StatusMessage);
      }
    }

    return result;
  } catch (error) {
    console.error("Error:", error.message);
    toast.error("Failed to add product to cart.");
  }
};

export default addProductToCartApi;
