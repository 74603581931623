import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../layout/product/productCard/productCard.css';
import Loader from '../loader/loadingCard';
import CartIcon from '../../../assests/icons/cartIcon.svg';
import giftIcon from "../../../assests/icons/giftIcon.png";
import { Close_Square } from "../../../utils/image";
import { useNavigate } from 'react-router-dom';
import { ADD_TO_CART, fetchRegisterId,WEB_CARTLIST } from "../../../Constants";
import moengage from "@moengage/web-sdk";
import addProductToCookies from '../../layout/cart/addProductToCookies';
import Select from "react-select";
import { toast } from "react-toastify";
import { useCart } from '../../layout/cart/cartContext';
import { setCookie, getCookie } from "../../../utils/cookieUtils"; // Adjust path to your cookieUtils
// import { fetchUserData,WEB_CARTLIST } from "../../../Constants";
import Cookies from "js-cookie";
import "react-toastify/dist/ReactToastify.css";
import fetchProductQuantityInCartList from '../../../api/fetchProductQuantityInCartList';
import urlReplace from '../../../services/urlReplace';

const API_URL = ADD_TO_CART;
const WebCartList = WEB_CARTLIST;


const SimilarProduct = ({ product,
  setIsToastMessage = () => {},
  productId,
  setCountItems

 }) => {
  const [activeProductId, setActiveProductId] = useState(null);
  const [variant1, setVariant1] = useState([]);
  const [variant2, setVariant2] = useState([]);
  const [mainItem ,setMainItem] = useState(null);
  const [prodPvid, setProdPvid] = useState(() => {
    const initialProduct = product?.SimiliarProduct?.[0]?.VariantData?.[0];
    return initialProduct ? initialProduct.pvid : null;
  });
  
  const { dispatch } = useCart();

  let API_URL = ADD_TO_CART;

  const [selectedVariant1, setSelectedVariant1] = useState(variant1);
  const [selectedVariant2, setSelectedVariant2] = useState(variant2);
  const [USIN, setUSIN] = useState(product?.SimiliarProduct?.[0]?.VariantData?.[0]?.USIN);
  
  const similarProducts = product?.SimiliarProduct || []; 
  const navigate = useNavigate(); 
 


  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      { breakpoint: 1200, settings: { slidesToShow: 4.99, slidesToScroll: 1 } },
      { breakpoint: 1024, settings: { slidesToShow: 4, slidesToScroll: 1 } },
      { breakpoint: 768, settings: { slidesToShow: 3, slidesToScroll: 1 } },
      { breakpoint: 600, settings: { slidesToShow: 3, slidesToScroll: 2 } },
      { breakpoint: 500, settings: { slidesToShow: 2, slidesToScroll: 1 } },
      { breakpoint: 420, settings: { slidesToShow: 1.8, slidesToScroll: 1 } },
      { breakpoint: 320, settings: { slidesToShow: 2, slidesToScroll: 1 } }
    ]
  };


  const fixUrlString = (str) => {
    if (typeof str !== 'string') {
      return ''; // or handle it in another way
    }
    return urlReplace(str)
  };

  const handleProductClick = (item) => {
    const variant1 = item.PriceQuantity[0]?.Variant1Value.replace(/\s+/g, '+');
    const variant2 = item.PriceQuantity[0]?.Variant2Value.replace(/\s+/g, '+');
    const prodName = urlReplace(item?.Name) 
                
  
    const url = `/product/${item.PriceQuantity[0]?.USIN || "undefined"}/${prodName}/${fixUrlString(variant1)}/${fixUrlString(variant2)}?productid=${item.Id}`;
    navigate(url);

    loaderComponent();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const submitAddToCart = (productId,item) => {
    setTimeout(()=>{
    setActiveProductId(productId);
    setVariant1(item?.VariantData?.Variant1Value || item.VariantData[0].Variant1Value);
    setVariant2(item?.VariantData?.Variant2Value || item.VariantData[0].Variant2Value);
   
    setMainItem(item);
    },100);

 }


  let loaderComponent = () => {
    let loaderWithOpacity = document.querySelector(".opacity__main__container");
    loaderWithOpacity.classList.add("active");
    setTimeout(() => {
      loaderWithOpacity.classList.remove("active");
    }, 1000);
  }


  const handleVariant1Change = (selectedOption) => {
    setSelectedVariant1(selectedOption?.value);
    setProdPvid(selectedOption?.pvid);
    setVariant1(selectedOption?.value);

    let USINData = mainItem?.VariantData?.filter((variant) => {
      return variant?.Variant1Value === selectedOption?.value && variant?.Variant2Value === variant2;
    })

    setUSIN(USINData[0]?.USIN);
  };

  const handleVariant2Change = (selectedOption) => {
    setSelectedVariant2(selectedOption?.value);
    setProdPvid(selectedOption?.pvid);
    setVariant2(selectedOption?.value)
    let USINData = mainItem?.VariantData?.filter((variant) => {
      return variant?.Variant2Value === selectedOption?.value && variant?.Variant1Value === variant1;
    })
    setUSIN(USINData[0]?.USIN)
  };

 let firstVariantUpdate = () => {
  if (selectedVariant1) {
    setSelectedVariant1(variant1);
  }
  if (selectedVariant2) {
    setSelectedVariant2(variant2);
  }
 }
  // Sync variant1 and variant2 props with state when they change
  useEffect(() => {
    firstVariantUpdate();
  }, [variant1, variant2]);

  // Log when the state actually updates
  useEffect(() => {
    setSelectedVariant1(selectedVariant1);
    setProdPvid(prodPvid);

  }, [selectedVariant1]);

  useEffect(() => {
    setSelectedVariant2(selectedVariant2);
    setProdPvid(prodPvid);

  }, [selectedVariant2]);

  const closeBtn = () => {
    setActiveProductId(false);  // Close the popup
  };
  
  const handleAddtoCart = async () => {
    if(fetchRegisterId()){
      let requiredVariantData = mainItem.PriceQuantity[0]
      let result = await fetchProductQuantityInCartList(mainItem.Id || activeProductId, requiredVariantData.Variant1Value,requiredVariantData.Variant2Value );
      if(result) {
        await addToCart();
      }
    }
    else {
      setTimeout(()=>{
        closeBtn();
      },100)
      const isNotLoggedProduct = mainItem.Id || "";
      const isNotLoggedQuantity = 1;
      let pVid = prodPvid;
   
      if (isNotLoggedProduct && isNotLoggedQuantity) {
        let reqproduct = mainItem;
        const requiredVariantData = reqproduct.PriceQuantity[0];
        if(addProductToCookies(
          reqproduct.Name,
          reqproduct.Id,
          isNotLoggedQuantity,
          requiredVariantData?.ListingPrice || 0,
          requiredVariantData?.MRP || 0,
          requiredVariantData.Note || "",
          pVid,
          selectedVariant1,//requiredVariantData.Variant1Value,
          selectedVariant2, // requiredVariantData.Variant2Value,
          requiredVariantData?.CoverImage?.[0].image
        )){
          let productDetails = mainItem;
          moengage.track_event(`Add_to_Cart_Clicked`, {
            "ID": ``,       
            "Product ID": productDetails.Id,
            "Product Name": productDetails.Name,
            "Base Price": productDetails.PriceQuantity[0].MRP,
            "Promotion Price": productDetails.PriceQuantity[0].ListingPrice,
            "Discount Percentage": parseInt( productDetails.PriceQuantity[0].Note.replace('(',"").replace(')',"").replace('%',"").split(" ")[0]),  
            [productDetails.PriceQuantity[0].Variant1Name ? productDetails.PriceQuantity[0].Variant1Name  :""] :productDetails.PriceQuantity[0].Variant1Value,
            ...(productDetails.PriceQuantity[0].Variant2Name  !== "" && {  [ productDetails.PriceQuantity[0].Variant2Name ] : productDetails.PriceQuantity[0].Variant2Value }),
            "USIN": productDetails.PriceQuantity[0].USIN ? productDetails.PriceQuantity[0].USIN :"" ,     
            "Screen Name": "Similar Products ",
          });
          setIsToastMessage(true);
          setTimeout(() => {
            setIsToastMessage(false);
          }, 3000);
        }
       
     
      }
      setCountItems(prev => parseInt(prev)   + 1)
    }
  }

  const addToCart = async () => {
    
   let RegisterId = fetchRegisterId();
    if (RegisterId) {
      try {
        const response = await fetch(API_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ProductId: mainItem.Id || activeProductId,
            RegisterId,
            ProductQuantity: 1,
            variant1_value:mainItem.Variant1Value || selectedVariant1,
            variant2_value:mainItem.Variant2Value || selectedVariant2,
            ApplicationType: "web"
          }),
        });

        if (!response.ok) {
          throw new Error(
            `Failed to add product to cart! Status: ${response.status}`
          );
        }

        const result = await response.json();
        setIsToastMessage(true);
        
        setTimeout(()=>{
          closeBtn();
        },100)

        setCountItems(prev => parseInt(prev)   + 1)
        let productDetails = mainItem;
        moengage.track_event(`Add_to_Cart_Clicked`, {
          "ID": `+91${JSON.parse(Cookies.get("Otp")).MobileNo}`,       
          "Product ID": productDetails.Id,
          "Product Name": productDetails.Name,
          "Base Price": productDetails.PriceQuantity[0].MRP,
          "Promotion Price": productDetails.PriceQuantity[0].ListingPrice,
          "Discount Percentage": parseInt( productDetails.PriceQuantity[0].Note.replace('(',"").replace(')',"").replace('%',"").split(" ")[0]),  
          [productDetails.PriceQuantity[0].Variant1Name ? productDetails.PriceQuantity[0].Variant1Name  :""] :productDetails.PriceQuantity[0].Variant1Value,
          ...(productDetails.PriceQuantity[0].Variant2Name  !== "" && {  [ productDetails.PriceQuantity[0].Variant2Name ] : productDetails.PriceQuantity[0].Variant2Value }),
          "USIN": productDetails.PriceQuantity[0].USIN ? productDetails.PriceQuantity[0].USIN :"" ,     
          "Screen Name": "Similar Products ",
        });
        // Dispatch action to update cart state
        dispatch({
          type: "ADD_TO_CART",
          payload: {
            id: activeProductId,
            ...result, // If your API returns the updated cart item details
          },
        });
        if(result.Toast)
          {toast(result.Toast);}
        await fetchCartItems(); // Ensure cart items are updated after adding
        // Notify user of successful addition
      } catch (error) {
        console.error("Error adding product to cart:", error);
        // Notify user of error
      } finally {
        setTimeout(() => {
          setIsToastMessage(false);
        }, 3000);
      }
    } 
  };


  const fetchCartItems = async () => {
    try {
      const RegisterId = fetchRegisterId();
      const response = await fetch(WebCartList, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
       
          RegisterId,
        }),
      });

      if (!response.ok) {
        throw new Error(
          `Failed to fetch cart items! Status: ${response.status}`
        );
      }

      const cartItemsData = await response.json();
      const itemCount = cartItemsData.Result ? cartItemsData.Result.length : 0;

    } catch (error) {
      console.error("Error fetching cart items:", error);
    }
  };
  

  

// Assuming product.Name, variant1, and variant2 are strings
const productUrlFixes = fixUrlString(product.Name);
const variant1UrlFixes = fixUrlString(variant1);
const variant2UrlFixes = fixUrlString(variant2);
  return (
    <div className="hm__product-container section_space hm__prod__slider">
      <div className="page-width">
        <div className="hm__prod__slider-title">
          <h2>Similar Products</h2>
        </div>
      </div>
      <div className='slider_container cm_arrow_side'>
        {similarProducts.length === 0 ? (
          <div className="loading-spinner">
            <Loader />
            <Loader />
            <Loader />
            <Loader />
            <Loader />
          </div>
        ) : (
          <Slider {...sliderSettings}>
            {similarProducts?.map((item) => {
              const variant1Options = item?.VariantData?.map(v => ({
                value: v?.Variant1Value,
                label: v?.Variant1Value,
                pvid: v?.pvid,
              })) || [];
              // setProdPvid(item?.VariantData[0].pvid);
              
              const variant2Options = item?.VariantData?.map(v => ({
                value: v?.Variant2Value,
                label: v?.Variant2Value,
                pvid: v?.pvid,
              })) || [];
              
              let isOutOfStock = item?.PriceQuantity[0]?.InStock;
              if(isOutOfStock === 'no'){
                isOutOfStock = false
              }else{
                isOutOfStock = true
              }
              
              const closeBtn = () => {
                setActiveProductId(false);  
              };

              const defaultVariant1 = variant1Options[0] || null;
              const defaultVariant2 = variant2Options[0] || null;

              const uniqueKey = `${item.Id}-${defaultVariant1?.value}-${defaultVariant2?.value}`;
              const isValid = (variant1 && selectedVariant1) || (variant2 && selectedVariant2);
              let mainProductVariantId = product?.PriceQuantity[0]?.Variant1Id; 
              
              return (
                <div className={`product-card ${!isOutOfStock ? "product_not_available" :""}`} key={uniqueKey}>
                 {!isOutOfStock && (
                      <div className="not_available_wrap" onClick={() => handleProductClick(item)}>
                        <div className="sold_out_label">
                          <p>Out Of Stock</p>
                        </div>
                      </div>
                  )}
                            
                  <div className="card-wrapper">
                    <div className="card-item">
                      <div className="card-image" onClick={() => handleProductClick(item)}>
                        <img
                          src={item?.PriceQuantity[0]?.CoverImage[0]?.image}
                          alt={item.Name}
                          className="motion-reduce"
                          loading="lazy"
                          width={item?.PriceQuantity[0]?.CoverImage[0]?.image.width || 533}
                          height={item?.PriceQuantity[0]?.CoverImage[0]?.image.height || 400}
                        />
                        {item?.PriceQuantity[0]?.Note && (
                          <div className="product__discount">
                            <span>{item?.PriceQuantity[0]?.Note.replace('(', '').replace(')', '')}</span>
                          </div>
                        )}
                              { isOutOfStock  && item.PriceQuantity[0]?.OfferPrice  && <div className="product_APP_OFFER_discount"><img src={giftIcon} className="icon" /><p>APP OFFER PRICE ₹{item.PriceQuantity[0]?.OfferPrice} </p></div>} 
                      </div>
                      <div className="card-content">
                        <div className="product_title" onClick={() => handleProductClick(item)}>
                          <div>{item.Name}</div>
                        </div>
                        <div className="product_price" onClick={() => handleProductClick(item)}>
                          <div className="price">
                            <div className="actual_price">₹{item?.PriceQuantity[0]?.ListingPrice}</div>
                          
                            { item?.PriceQuantity[0]?.ListingPrice!==item.PriceQuantity[0]?.MRP && <div className="compare_price">
                  <s>₹{item.PriceQuantity[0]?.MRP}</s>
                </div>}
                            {item.PriceQuantity[0]?.Note && (
                              <div className="product__discounts green">
                                <span>{item.PriceQuantity[0]?.Note}</span>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="product_btns">
                          <div className="product_atc_btn" onClick={() => submitAddToCart(item.Id, item)}
                          >
                            <img src={CartIcon} alt="icon icon-cart" />
                            <div className="btn_text">Add To Cart</div>
                          </div>
                        </div>
                      </div>
                      {/* variant popup */}
                      <div className={`card_variant_main ${activeProductId === item.Id ? 'active_var' : ''}`}>
                        <div className="card_var_title">
                          <p>Select Option</p>
                          <div className="close_btn cursor-pointer" onClick={closeBtn}>
                            <img src={Close_Square} alt="close" />
                          </div>
                        </div>
                        {item && item?.VariantData && item?.VariantData[0] && item?.VariantData[0]?.Variant1Value ? (
                          <div className="card_var_body">
                          {/* Remove duplicates for variant1 options */}
                          {item?.VariantData[0]?.Variant1Value && (
                            <div className="card_var_items">
                              {/\d/.test(item?.VariantData?.Variant1Value) ? <p>Size</p> : <p>Flavour</p>}
                              <div className="card_var_select">
                                <Select
                                  options={Array.from(new Set(variant1Options.map(option => option.value)))
                                    .map(value => variant1Options.find(option => option.value === value))}
                                  value={
                                    variant1Options
                                      ? variant1Options.find(option => option.value === selectedVariant1) || selectedVariant1
                                      : selectedVariant1
                                  }
                                  onChange={handleVariant1Change}
                                />
                              </div>
                            </div>
                          )}

                          {/* Remove duplicates for variant2 options */}
                          {item?.VariantData[0]?.Variant2Value &&  (
                            <div className="card_var_items">
                              <p>{'Size'}</p>
                              <div className="card_var_select">
                                <Select
                                  options={Array.from(new Set(variant2Options.map(option => option.value)))
                                    .map(value => variant2Options.find(option => option.value === value))}
                                  value={
                                    variant2Options
                                      ? variant2Options.find(option => option.value === selectedVariant2) || selectedVariant2
                                      : selectedVariant2
                                  }
                                  onChange={handleVariant2Change}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                        ):""}
                       
                        <div className="card_var_footer">
                          <div className="product_btns">
                            <div className="product_atc_btn" onClick={handleAddtoCart}>
                              <img src={CartIcon} alt="icon icon-cart" />
                              <div className="btn_text">Add To Cart</div>
                            </div>
                            <div className="view_product_btn">
                              <a href={`/product/${USIN}/${productUrlFixes}/${fixUrlString(selectedVariant1)}/${fixUrlString(selectedVariant2)}?productid=${item.Id}`}>
                                View Product
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* variant popup end */}
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        )}
      </div>
    </div>
  );
};

export default SimilarProduct;
