import { toast } from "react-toastify";
import { fetchRegisterId, fetchUserData, WEB_CARTLIST,WEB_CART_QUANTITY_UPDATE } from "../Constants";

 const fetchProductQuantityInCartList = async (productId,variant1,variant2,quantity) => {
  let toastShown = false;
  const handleQuantityChange = async (newQuantity, itemId) => {
    try {
     
     
      const response = await fetch(WEB_CART_QUANTITY_UPDATE, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          RegisterId:fetchRegisterId(),
          Quantity: newQuantity,
          CartListId: itemId,
        }),
      });

      if (!response.ok) {
        throw new Error(
          `Failed to update quantity! Status: ${response.status}`
        );
      }

    
    } catch (error) {
      console.error("Error updating quantity or fetching cart list:", error);
    }
  };

    if(fetchUserData()){
        let result=true;
        try {
          const response = await fetch(WEB_CARTLIST, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              RegisterId: fetchRegisterId(),
              ApplicationType:"web",
            PaymentType: ''
              
            }),
          });
    
          if (!response.ok) {
            throw new Error(`Failed to fetch cart items! Status: ${response.status}`);
          }
    
          let cartItemsData = await response.json();
          cartItemsData = cartItemsData?.Result;
          
          cartItemsData.map((items) => {
            if (
              items.variant1_value === variant1
               &&
               variant2 === items.variant2_value
               &&
               items.ProductId === productId
            ) {
              if(quantity){
                if(parseInt(items.ProductQuantity) + parseInt(quantity)  > 4){
                 // setQuantity(4);
              
                   handleQuantityChange(4, items.id);
                   if (!toastShown) { // Show toast only if it hasn't been shown
                    toast("Cannot add more than 4 Quantity");
                    toastShown = true; // Mark toast as shown
                  }      
                  result= false;             
                }
                else {
               
                  result= true;
                }
              }
              else{
                if(items.ProductQuantity > 3){
                  if (!toastShown) { // Show toast only if it hasn't been shown
                    toast("Cannot add more than 4 Quantity");
                    toastShown = true; // Mark toast as shown
                  }
                  result= false;          
                }
                else {
                  //console.log("matched");
                  result= true;
                }
              }
            }
          });
          return result;
    
       
        } catch (error) {
          //console.log(error.message);
        }
    }else{

    }
   
  };

  export default fetchProductQuantityInCartList
