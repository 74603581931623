import validator from 'validator';

export const validateAccountForm = (formData,setErrors) => {
    let valid = true;
    const regex = /^[A-Za-z\s]+$/; // Regular expression for alphabetic characters
    // if (!regex.test(input)) {
    //   alert('Please enter letters only.');
    //   return false; // Prevent form submission
    // }
   
    let errors = { firstName: '',lastName:'', email: '' };
    if (!formData.firstName || !formData.firstName.trim()) {
      errors.firstName = ' First Name is Required';
      valid = false;
    }else if(!regex.test(formData.firstName)){
      errors.firstName = 'Please enter letters only.';
      valid = false;
    }
    if (!formData.lastName || !formData.lastName.trim()) {
        errors.lastName = 'Last Name is Required';
        valid = false;
      }else if(!regex.test(formData.lastName)){
        errors.lastName = 'Please enter letters only.';
        valid = false;
      }
    

    if (!formData.email) {
      errors.email = 'Email is Required';
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email) || !validator.isEmail(formData.email)) {
      errors.email = 'Email is Invalid';
      valid = false;
    }
    setErrors(errors);
    return valid;

}



export const validateAddressForm = (formData,setErrors) => {
    let valid = true;
    const errors = {};
    if (!formData.building || !formData.building.trim()) {
        errors.building = 'Required';
        valid = false;
      }
    if (!formData.street || !formData.street.trim()) {
      errors.street = 'Required';
      valid = false;
    }
    if (!formData.city ) {
      errors.city = 'Required';
      valid = false;
    }
    if (!formData.state) {
      errors.state = 'Required';
      valid = false;
    }
    if (!formData.pincode ){
        errors.pincode = 'Required';
        valid = false;
    }else if( !/^\d{6}$/.test(formData.pincode)) {
      errors.pincode = 'Invalid';
      valid = false;
    }
  
    setErrors(errors);
    return valid;


}