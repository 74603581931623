import {PRODUCT_LIST,WEB_PRODUCT_LIST,BRAND_DETAILS,BEST_SELLER} from "../Constants";

export const fetchParentCategoryProductList = async (categoryName,subCategoryName,categoryId,page,type) => {
    
  let payload ,  API_NAME;
  switch (type) {
    case 'category':
      if(subCategoryName){
        API_NAME= WEB_PRODUCT_LIST;
      
        payload = {
         Pagination: page,
         SubCategoryid: categoryId,
         ParentCategoryName: categoryName,
         ApplicationType: "web",
       };
      }else {
        API_NAME= PRODUCT_LIST;
      
         payload = {
          Pagination: page,
          ParentCategoryName: categoryName,
          LevelType: "groupCategory",
          groupCategoryId: categoryId,
          ApplicationType: "web",
        };
      }
    
      break;
    case 'brands':
      API_NAME= BRAND_DETAILS;
      
         payload = {
          Pagination: page,
          BrandId: categoryId ,
          ApplicationType: "web",
        };
  
    

       
        break;
    case 'best-seller':
      API_NAME= BEST_SELLER;
      
      payload = {
       Pagination: page,
       panelId: categoryId ,
       ApplicationType: "web"
      




     };

       
        break;

    default:
        
}

        
      
          try {
            const response = await fetch(API_NAME, {
              method: "POST",
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
              body: new URLSearchParams(payload).toString(),
            });
      
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
      
            const data = await response.json();
          
       //console.log(data)
        return data
      } catch (error) {
        //console.log(error)
        
      } 
      
   
  
    return fetchParentCategoryProductList;
  };