import React from 'react'
import { Link } from 'react-router-dom'
import Cookies from "js-cookie";
import moengage from "@moengage/web-sdk";

const AvailableBoxs = ({categories , brands}) => {
	
	const formatCategoryName = (name) =>
		encodeURIComponent(name)

let boxesData = []
	if(categories){
		boxesData = categories.map(category => ({
			img: category.CategoryImage,
			title: category.CategoryName,
			id: category.CategoryId,
			pCategory: category.ParentCategoryName ,
			url:`/category/${formatCategoryName(category.ParentCategoryName).replace(/%20/g, "+")?.replace("%26","&")}/${formatCategoryName(category.CategoryName)?.replace(/%20/g, "+")?.replace("%26","&")}?id=`+category.CategoryId
		}))
		
	}
	else if(brands){

		boxesData = brands.map(brand => ({
			img: brand.BrandImage,
			title: brand.BrandName,
			id: brand.BrandId,
			pCategory: null ,
			url:`/brands/${brand.BrandName.replace(/[^a-zA-Z0-9 ]/g, "")
				.replace(/\s+/g, "-")
				.toLowerCase()}?id=`+brand.BrandId
		}))


	}

	const handleMoengageBrandEvent = (brand) => {
 
		moengage.track_event("Brands_Viewed", {
		  "ID": (Cookies.get("Otp")) ? `+91${JSON.parse(Cookies.get("Otp")).MobileNo}` :"", 
		  "Brand Name": brand, 
		  "Screen Name" : "Store"
	
		  });
		  
		
		
	}

	const handleMoengageCategoryEvent = (categoryName,SubCategoryName) => {
 
		moengage.track_event("HomePage_Main_Category_Viewed", {
			"ID": (Cookies.get("Otp")) ? `+91${JSON.parse(Cookies.get("Otp")).MobileNo}` :"", 
			"Category Name": categoryName,
			"Sub Category Name": SubCategoryName,
			"Screen Name" :"Store"
		  });  	
	}

  return (


<div className="product-wrapper ">
<h6>{ categories ? "Available Products" : "Available Brands"}</h6>
<div className="product-container ">
	{ boxesData && boxesData.map((data,index) => (
		 <Link key={index} className="product-box " to={data.url} onClick={() => categories ? handleMoengageCategoryEvent(data.pCategory,data.title) : handleMoengageBrandEvent(data.title)}>
		<img src={data.img}/>
		<p>{data.title.length > 13 ? data.title.slice(0,10) + "..." : data.title}</p>
		</Link>
	))}

 
</div>
</div>
  )
}

export default AvailableBoxs
