import "./popUp.css";
import OrderUnsuccessfull from "../../../../assests/image/OrderUnsuccessfull.png";
//import SuccessfullTick from "../../../../assests/icons/succesfullTick.png";
import { Link } from "react-router-dom";

const OrderFailedPopup = ({setManipulatedOrder , ManipulatedOrder}) => {

 
 //console.log("Failed here")
if(ManipulatedOrder){
  return (
    <div className="successfull-modalWrapper">
      <div className="successfull-modalContent">
    {/* <img src={OrderUnsuccessfull} className="imageUnsuccessfull" alt="logo"/> */}

     <div className="imageUnsuccessfull">Caugth!!!</div>  
        
          <Link to={"/cart"} className="back-to-cart">
            {" "}
            <button className="back-to-cart">Return to Cart</button>
          </Link>{" "}

      
      </div>
    </div>
  );
}else {
  return (
    <div className="successfull-modalWrapper">
      <div className="successfull-modalContent">
    <img src={OrderUnsuccessfull} className="imageUnsuccessfull" alt="logo"/>

       
        
          <Link to={"/cart"} className="back-to-cart">
            {" "}
            <button className="back-to-cart">Return to Cart</button>
          </Link>{" "}

      
      </div>
    </div>
  );
}

};
export default OrderFailedPopup;
///order-status/order?id=${orderList}
