import React, { useState } from "react";
import './Content.css';
import DownCircleIcon from "./../assests/icons/DownCircleIcon.png"

const MuscleBlazeBrandContent = () => {
  const [isExpaneded, setIsExpanded] = useState(true);

  const handleExpansion = () => {
    setIsExpanded(!isExpaneded);
  };

  return (
    <div className="home-content-wrapper page-width">
        <div  className="home-content-container ">
        <h1>Buy MuscleBlaze MB Products Online in India at Affordable Prices</h1>
 

<p className= {isExpaneded ? '' : "view-more"}  >
At Unfit Mart, we bring you the best in health and fitness with MuscleBlaze MB, a top brand trusted by fitness enthusiasts across India. Whether you're looking to build muscle, boost your energy, or improve overall health, MuscleBlaze has products to support every step of your fitness journey.</p>
  {isExpaneded && (
	<div>
	       <section> <h2>Why Choose MuscleBlaze?</h2>
			<p>MuscleBlaze is known for its commitment to quality, innovation, and authenticity. Since 2012, the brand has been a pioneer in creating high-quality supplements and health foods that cater specifically to the Indian fitness community.</p>
			<ul>
				<li><strong>Authenticity You Can Trust:</strong> MuscleBlaze was the first brand in India to introduce a product authenticity check through SMS and lab-certified protein test results, ensuring you always get genuine products.</li>
				<li><strong>Innovative Products:</strong> From industry-first features like the scoop on top to scientifically researched formulations, MuscleBlaze is constantly pushing the boundaries of what’s possible in health supplements.</li>
				<li><strong>High-Quality Ingredients:</strong> The brand sources the finest whey protein and other ingredients from around the world, making sure you only get the best.</li>
				<li><strong>Research-Driven:</strong> MuscleBlaze products are backed by scientific research to deliver effective results, from muscle growth to overall wellness.</li>
			</ul>
		</section>
	
		<section>
			<h2>Explore MuscleBlaze's Product Range</h2>
			<p>At Unfit Mart, we offer a wide selection of MuscleBlaze products to fit different fitness goals and budgets:</p>
			<ul>
				<li><strong><a href="https://unfitmart.com/product/UM06501966/muscleblaze-biozyme-performance-whey-protein/Kesar%20Thandai/750%20gm?productid=840">Biozyme Performance Whey:</a></strong> Designed for 50% better protein absorption, this whey protein comes in delicious flavors like Rich Chocolate and Chocolate Hazelnut.</li>
				<li><strong><a href="https://unfitmart.com/product/UM06501834/muscleblaze-super-gainer-xxl/chocolate/3-kg?productid=1133">Super Gainer Black:</a></strong> Ideal for those looking to gain muscle mass, this gainer is enhanced for appetite support, digestion, and testosterone levels.</li>
				<li><strong><a href="https://unfitmart.com/product/UM06501386/muscleblaze-fit-high-protein-oats/dark-chocolate/2-kg?productid=831">Fit High Protein Oats:</a></strong> Start your day with these gluten-free, protein-packed breakfast options that keep you energized and full.</li>
			</ul>
		</section>
	
		<section>
			<h2>Top-Selling MuscleBlaze Products at Unfit Mart</h2>
			<p>Join the fitness community that trusts MuscleBlaze for results. Here are some of our best-selling products available at Unfit Mart:</p>
			<ul>
				<li><strong>Biozyme Performance Whey (Chocolate Hazelnut, 2 kg):</strong> Certified for quality by Labdoor USA, this whey protein is a favorite for its taste and effectiveness.</li>
				<li><strong>Fuel One Whey Protein (Chocolate, 4 kg):</strong> Packed with 24g of protein per serving, along with essential amino acids, this whey protein is great for anyone aiming to build lean muscle.</li>
				<li><strong>High Protein Muesli (Dark Chocolate & Cranberry, 1 kg):</strong> A ready-to-eat snack loaded with 18g of protein, making it a tasty and healthy choice for snack time or breakfast.</li>
			</ul>
		</section>
	
		<section>
			<h2>Health Benefits of MuscleBlaze Products</h2>
			<p>MuscleBlaze supplements are formulated to help you achieve your fitness goals. Here are some of the key benefits you can enjoy by incorporating these products into your routine:</p>
			<ul>
				<li><strong>Muscle Growth & Recovery:</strong> MuscleBlaze Whey Protein and Mass Gainers provide essential proteins and amino acids to support muscle repair and growth after intense workouts.</li>
				<li><strong>Improved Strength & Endurance:</strong> Products like MuscleBlaze Creatine and BCAA enhance your strength, endurance, and performance during workouts.</li>
				<li><strong>Weight Management:</strong> Whether you're trying to lose weight or gain muscle, MuscleBlaze offers products like Whey Protein Isolate and Super Gainer to fit your needs.</li>
				<li><strong>Boosted Energy Levels:</strong> Stay energized throughout the day with MuscleBlaze Protein Bars and Raw Whey Protein, which provide a quick energy boost when you need it most.</li>
				<li><strong>Overall Wellness:</strong> With supplements like MuscleBlaze Multivitamins and Fish Oil, you can support your general health and ensure you're getting all the essential nutrients.</li>
			</ul>
		</section>
	
		<section>
			<h2>How to Choose the Right MuscleBlaze Product</h2>
			<p>Based on your fitness objectives, you can choose the best supplement. Here’s a quick guide:</p>
			<ul>
				<li><strong>Muscle Building:</strong> Choose products like <a href="https://unfitmart.com/product/UM06502006/muscleblaze-100-whey-protein/chocolate/1-kg?productid=812">Whey Protein</a> or <a href="https://unfitmart.com/product/UM06501834/muscleblaze-super-gainer-xxl/chocolate/3-kg?productid=1133">Mass Gainers</a> to support muscle growth.</li>
				<li><strong>Strength & Performance:</strong> Look for <a href="https://unfitmart.com/product/UM06501336/muscleblaze-bcaa-pro-ripped/green-apple/340-gm?productid=808">BCAA</a> and <a href="https://unfitmart.com/product/UM06501378/muscleblaze-creatine-monohydrate/unflavoured/100-gm?productid=826">creatine</a> to enhance workout performance.</li>
				<li><strong>Weight Management:</strong> Opt for <a href="https://unfitmart.com/product/UM06501945/muscleblaze-biozyme-iso-zero-low-carb-whey-protein-isolate/ice-cream-chocolate/2-kg?productid=1171">Whey Protein Isolate</a> or low-carb options for better weight control.</li>
				<li><strong>General Health:</strong> Try multivitamins and <a href="https://unfitmart.com/product/UM06501380/muscleblaze-omega-3-fish-oil/unflavoured/90-capsules?productid=827">omega-3 supplements</a> for overall wellness.</li>
			</ul>
		</section>
	
		<section>
			<h2>Buy 100% Authentic MuscleBlaze Products at Unfit Mart</h2>
			<p>At Unfit Mart, we make sure you get 100% authentic MuscleBlaze products delivered to your doorstep. Plus, with our affordable prices, buying quality supplements has never been easier.</p>
			<p><strong>Shop online with us today, and take the next step in your fitness journey with MuscleBlaze!</strong></p>
			</section>
			</div>
	
    )}
    <button onClick={handleExpansion} className="view-more-btn">
        {isExpaneded ? "View Less" : "View More"} <img className={`icon ${isExpaneded ?  "rotate-180" : ""}`} src={DownCircleIcon} />
      </button>

    </div>
    </div>
  );
};

export default MuscleBlazeBrandContent;
