import React from 'react';
import ReturnIcon from './../../../../assests/icons/ReturnIcon.svg'
import {CANCEL_ORDER} from "../../../../Constants";
import { useNavigate } from 'react-router-dom';
import moengage from "@moengage/web-sdk";
import Cookies from "js-cookie";
import variantArray from '../../../common/variantArray';
const PopUp = ({setConfirmPopUp,orderId,returnId,orderAmountDetails}) => {

  //console.log(orderAmountDetails);

  const navigate= useNavigate();
  const moengageEvent = () => {


    let ProductName = [],ProductID =[],Price = [] ,Flavour=[],Size=[],Quantity=[],PaymentType=[],MRP=[],Note=[],USIN=[];
    let variant1_name,variant2_name;
    let data = {};
    const firstKey = orderAmountDetails.Result[0]?.variant1_name;
 
    orderAmountDetails.Result.map((item) => {
        ProductName.push(item.Title);
        ProductID.push(item.Id)
        Price.push(item.Price)
      
        Flavour.push(item.variant1_value)
        Size.push(item.variant2_value)
        Quantity.push(item.ProductQuantity)
        PaymentType.push(item.PaymentType)
        MRP.push(item.MRP)
        Note.push(parseInt( item.Note.replace('(',"").replace(')',"").replace('%',"").split(" ")[0]))
        USIN.push(item.USIN)
        if (item.variant2_name !== "" && typeof item.variant2_name !== undefined) {
          //console.log(item.variant1_name,item.variant1_value,item.variant2_name,item.variant2_value)
          variantArray(
            data,
            firstKey,
            item.variant1_name,
            item.variant1_value,
            item.variant2_name,
            item.variant2_value,
    
          );
        } else if (
          item.variant1_name !== "" &&
          typeof item.variant1_name !== undefined
        ) {
          //console.log(item.variant1_name,item.variant1_value)
          variantArray(data,firstKey,item.variant1_name, item.variant1_value);
        }
        // variant1_name= item.variant1_name
        // variant2_name= item.variant2_name




    })
    moengage.track_event("Cancel_Order", {
      "ID": `+91${JSON.parse(Cookies.get("Otp")).MobileNo}` , 
      "Product Name" :ProductName,
      "Product ID" : ProductID,
      "Promotion Price": Price,
      // [variant1_name? variant1_name  :""] :Flavour,
      // ...(variant2_name !== "" && {  [variant2_name ] :Size }),

      "USIN": USIN ,
      "Quantity" : Quantity,  
      "Total Pay" : orderAmountDetails.TotalPayableAmount,
      "Coupon Discount" : orderAmountDetails.Discount,
      "Customer Id" : JSON.parse(Cookies.get("userData")).id ? JSON.parse(Cookies.get("userData")).id : JSON.parse(Cookies.get("resigterId")).RegisterId,
      "Customer Name" : JSON.parse(Cookies.get("userData")).userName ? JSON.parse(Cookies.get("userData")).userName : JSON.parse(Cookies.get("userData")).firstName+" "+JSON.parse(Cookies.get("userData")).lastName,
      "Base Price":MRP,
      "Discount Percentage": Note,
      "OrderType": PaymentType,
      ...data

      });
  



  }
    const cancelOrder = async () => {
        try {

            const payload = new URLSearchParams({
                OrderId: orderId 
              });
        
          const response = await fetch(CANCEL_ORDER, {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body:  payload
          });
     
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
     
          
          const data = await response.json();
 
          if(data.Status){
            moengageEvent();
            navigate('/account/orders')
          }
         
         
        } catch (error) {
          console.error('Error fetching data:', error);
          throw error;
        }
      };
    const handleCancel = () => {
        cancelOrder()
        setConfirmPopUp(false) 
    }
    const handleDoNotCancel = () => {
        setConfirmPopUp(false)
    }
    if(returnId)
    {
      return (
        <div className="confirmCOD-modalWrapper">
    <div className="confirmCOD-modalContent inLine">
    <img src={ReturnIcon} className='confirmIcon'/>
      <div  className="confirmText"> Return Your Order</div>
      <div  className=' inLine'> <p className='text'>For return inquiries, please contact our customer support team at <span className='discount_price inLine'>1800 202 5157</span> </p> </div>
     
      <div className="buttons">
    
      <button className="home-button" onClick={handleDoNotCancel}>Close</button>
      </div>
      
    </div>
  </div>
      )
    }
    else {
  return (
    <div className="confirmCOD-modalWrapper">
    <div className="confirmCOD-modalContent">
      
      <div  className="confirmText"> Confirm to Cancel Your Order</div>
      <div className="buttons">
      <button className="home-button" onClick={handleCancel} >Yes,Cancel Order</button>
      <button className="order-button" onClick={handleDoNotCancel}>No, Do not Cancel</button>
      </div>
      
    </div>
  </div>
  )
}
}

export default PopUp
