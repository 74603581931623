// src/pages/Home.js
import React, { useState, useEffect } from 'react';
import HmSlider from '../components/common/bannerSlider/homeSlider/hmSlider';
import ProductSlider from '../components/common/productSlider/productSlider';
import BannerSlider from '../components/common/bannerSlider/offerBannerSlider/offerBannerCard';

import TopBrands from '../components/common/brandGrid/brandGrid';
import IconsContainer from '../components/common/homeIcons/hmIconsGrid';
import AmazingBrand from '../components/common/bannerSlider/amazingBrand/amazingBrand';

import DealsOfDay from "../components/common/bannerSlider/dailyDeals/dailyDeals";

import ImageSection from "../components/common/imageSection/imageSection";
import ExtraProductCardSlider from "../components/common/productSlider/extraProductSlider";
import LogoListSlider from '../components/common/logoList/logoList';
import InviteSection from "../components/common/inviteSection/inviteSection";
import { fetchCategoryFromApi } from "../components/layout/category/categoryApi";
import ToastMessage from '../components/common/toastMessage/toastMessage';
import MetaTagsComponent from '../components/layout/metaTag/metaTagComp';
import {  fetchDashBoardData } from '../api/HomePageApi';
import { useLocation, useParams } from 'react-router-dom';
import OfferStoreBannerSlider from '../components/common/bannerSlider/offerStoreBannerSlider';

const PreviewDynamicDashboard = ({ setCountItems }) => {

  const [isToastMessage, setIsToastMessage] = useState(false);
  const [dashBoardData,setDashBoardData] = useState([false]);

  const location = useLocation(); 
  const query = new URLSearchParams(location.search); 
  const id = query.get('id'); 
  //console.log(id)


  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchDashBoardData(id);
        //console.log(data.result);
        setDashBoardData(data.result)
       
      } catch (error) {
        console.error('Error fetching category data:', error);
      }
    };

    fetchData();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MetaTagsComponent />

      {dashBoardData && dashBoardData.map((item) => {
        if (item.type === "banner") {
          return <HmSlider data={item.data.banner} key={item.id} screenName={item.name}/>;
        } else if(item.type === "dailyDeal" && item.data.dailyDeal.length > 0) {
          return  <DealsOfDay data={item.data.dailyDeal} name={item.name} key={item.id} />;
        }
        else if(item.type === "offerStore" && item.data.offerStore.length > 0) {
          return <OfferStoreBannerSlider data={item.data.offerStore} name={item.name}  key={item.id} />;
        }  else if(item.type === "brandDiscountAd" && item.data.brandDiscountAd.length > 0) {
          return <AmazingBrand data={item.data.brandDiscountAd} name={item.name}  key={item.id} />;
        }else if(item.type === "productList" && item.data.productList.length > 0 ) {
          return  <ProductSlider 
        setCountItems={setCountItems} 
        categoryName={item.name} 
        categoryId={2} 
        key={item.id} 
        setIsToastMessage={setIsToastMessage}
        data={item.data.productList}
        reDirectionId={item.redirectionId}
      />;
        } else if(item.type === "subCategory"  && item.data.productList.length > 0 ) {
          return  <ProductSlider 
        setCountItems={setCountItems} 
        categoryName={item.name} 
        categoryId={2} 
        key={item.id} 
        setIsToastMessage={setIsToastMessage}
        data={item.data.productList}
        reDirectionId={item.redirectionId}
        parentCategory={item.categoryName}
      />;
        }
        else if(item.type === "brandPlacement" && item.data.brandPlacement !== null ) {
          return  <ExtraProductCardSlider 
        setCountItems={setCountItems} 
        categoryName={item.name} 
        categoryId={6} 
        key={item.id} 
        setIsToastMessage={setIsToastMessage}
        data={item.data.brandPlacement}
      />;
        }else if(item.type === "unfitOffer" && item.data.unfitOffer.length >0) {
          return  <BannerSlider data={item.data.unfitOffer} name={item.name} key={item.id} />;
        } 
        else if(item.type === "brand" && item.redirectionId === "brand" && item.data.brand.length > 0 ) {
          return  <TopBrands data={item.data.brand} name={item.name} key={item.id} />;
        }
        else if(item.type === "brand" && item.redirectionId === null && item.data.brand.length > 0 ) {
          return  <LogoListSlider  data={item.data.brand} name={item.name} key={item.id} />;
        }  else if(item.type === "whyChooseUnfit" && item.data.whyChooseUnfit !== null ) {
          return  < IconsContainer   data={item.data.whyChooseUnfit} name={item.name} key={item.id} />;
        } else if(item.type === "footerImage" && item.data.footerImage !== null){
        return  <ImageSection data={item.data.footerImage} key={item.id} />
        }

        
      })}
    

      <InviteSection />
      {isToastMessage && <ToastMessage />}

    </>
  );
};

export default PreviewDynamicDashboard;
