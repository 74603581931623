import React from 'react'
import { loader_icon } from '../../../utils/image';
import loader from '../../../assests/image/stanta_loader.gif'
//theme
const CustomLoader = () => {
 
  return (
    <div className="page_loading"><img src={loader} alt="page_loader" /></div>
  )
}

export default CustomLoader