import React, { useState, useEffect } from 'react';
import ProductAPI from '../../layout/product/productCard/productCard';
import './collectionProduct.css'; // Ensure correct path to your CSS file
import Loader from '../../common/loader/loadingCard';
import { WEB_PRODUCT_LIST } from '../../../Constants';

const CollectionProd = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const payload = new URLSearchParams({
          Pagination: '', // Adjust the number of items you want to fetch
          CategoryName: '',
          groupCategoryId: '0',
          LevelType: 'shopDashboard'
        });

        const response = await fetch(WEB_PRODUCT_LIST, {
          method: 'POST',
          headers: {
            'UserType': 'customer',
            'RegisterId': '66926',
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: payload
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        //console.log(data.Result[0], "data");
        setProducts(data.Result[0] || []); // Ensure we get the products array from the data
        setLoading(false); // Set loading to false when data is fetched
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false); // Set loading to false on error
      }
    };

    fetchProducts();
  }, []); // Empty dependency array ensures this runs only once on component mount

  return (
    <div className="collection__wrapper">
      <div className="page-width padding-lr20">
        <h2 className="heading__h1">Collection All</h2>
        {loading ? (
           <div className='product-list'>
           <Loader />
           <Loader />
           <Loader />
           <Loader />
           <Loader />
         </div> // Display five loaders while fetching data
        ) : (
          <div className='product-list'>

          {products.map((product) => (
            product.Type === "product" && (
              <ProductAPI key={product.id} product={product} />
            )    
              ))
            }
          </div>

        )}
       
      </div>
    </div>
  );
};

export default CollectionProd;
