import React, { useState } from "react";
import './Content.css';
import DownCircleIcon from "./../assests/icons/DownCircleIcon.png"

const BoltBrandContent = () => {
  const [isExpaneded, setIsExpanded] = useState(true);

  const handleExpansion = () => {
    setIsExpanded(!isExpaneded);
  };

  return (
    <div className="home-content-wrapper page-width">
        <div  className="home-content-container ">
        <h1>Buy BOLT Nutrition online in India for Best prices</h1>

<p className= {isExpaneded ? '' : "view-more"}  >
  Welcome to BOLT Nutrition, where advanced science meets quality nutrition! BOLT is not just another brand-it's commitment to helping elevate your fitness journey. Pioneers in incorporating the mighty power of phycocyanin into our products, BOLT has been declared the G.O.A.T (Greatest of All Time) in the protein supplement market. In the market, you get to find unique green and blue particulate blends that BOLT products are going to fuel your workouts as well as help you make incredible results.</p>
  {isExpaneded && (
        <div>

    <h2>Why Choose BOLT Nutrition?</h2>
    <h3>A trusted name in Health and Nutrition.</h3>
    <p>At BOLT, we do not believe that fitness is just a number on a scale; it is feeling, performance, and the living of every day. Formulated by the health care, pharmaceutical, and nutraceutical experts, we have developed our products with extreme attention to detail. Extremely serious issues regarding safety standards, quality of ingredients, and efficacy allow us to offer supplements of actual value.</p>
    <h2>Advantages of BOLT Nutrition products</h2>
    <h3>Quality without Compromise</h3>
    <p>BOLT believes in quality ingredients. The research and development and patented processes all ensure that all of our products not only are safe but will also work for your needs. It may be to support muscles, speed up recovery time, or add energy into your day. BOLT has a product for this purpose.</p>
    <h2>Find your BOLT Nutrition Range</h2>
    <h3>Fuel Your Fitness with BOLT</h3>
    <h3>Protein Supplements</h3>
    <ul>
    <li><strong>Whey Protein:</strong>Derived from milk, whey protein is a fast-absorbing protein source that aids in muscle growth and recovery.</li>

  <li><strong>Plant-Based Protein:</strong>A blend of plant-based protein sources like pea, soy, and rice, offering a sustainable and versatile option for vegetarians and vegans.</li>
    </ul>
    <h3>Pre-Workout Supplements</h3>
    <ul>
      <li><strong>Pre-Workout Powders:</strong>Formulated to provide a surge of energy, focus, and endurance before workouts.</li>
      <li><strong>Pre-Workout Tablets:</strong>Convenient tablets that deliver essential nutrients and stimulants for a quick pre-workout boost</li>
    </ul>
    <h3>Intra-Workout Supplements</h3>
    <ul>
      <li><strong>BCAAs (Branched-Chain Amino Acids):</strong>Essential amino acids that support muscle protein synthesis and reduce muscle breakdown during workouts.</li>
      <li><strong>EAAs (Essential Amino Acids):</strong>A complete profile of essential amino acids to fuel muscle recovery and growth.</li>
    </ul>
    <h3>Post-Workout Supplements</h3>
    <ul>
      <li><strong>Creatine:</strong>A popular supplement that increases strength and power by enhancing ATP production.</li>
      <li><strong>Glutamine:</strong>A conditionally essential amino acid that supports immune function and muscle recovery.</li>
    </ul>
    <h3>Weight Gainers</h3>
    <ul>
      <li><strong>Mass Gainers:</strong>High-calorie formulas that combine protein, carbohydrates, and other nutrients to promote weight gain and muscle building</li>
    </ul>
    <br></br>
    <h2>Best-Selling BOLT Nutrition Products</h2>
    <p>Popular and Trusted by Users</p>
    <ul>
    <strong>1.<a href="https://unfitmart.com/product/UB02102481/bolt-100-whey-protein-powder/unflavoured/2-lb?productid=240" target="_blank">Bolt Nutrition Whey Protein</a></strong> 
    <p>Pure protein for muscle growth and recovery</p>
    <strong>2.<a href="https://unfitmart.com/product/UB02103267/bolt-micronised-creatine-monohydrate-3000-powder/unflavored/100-gm?productid=1955" target="_blank">Bolt Micronized Creatine</a></strong>
    <p>Powerhouse for strength and explosive power</p>
    <strong>3.<a href="https://unfitmart.com/product/UB02100303/bolt-pre-workout-supplement/orange-tangy/300-gm?productid=244" target="_blank">Bolt Nutrition Pre-Workout Supplement</a></strong>
    <p>Intense energy and focus for workouts</p>
    <strong>4.<a href="https://unfitmart.com/product/UB02100297/bolt-mass-gainer/piedmont-chocolate/2-lb?productid=242" target="_blank">Bolt Mass Gainer</a></strong>
    <p>Weight gain and muscle-building support</p>
    <strong>5.<a href="https://unfitmart.com/product/UB02100314/bolt-instantized-bcaa/blueberry-blast/240g?productid=251" target="_blank">Bolt Nutrition BCAA</a></strong>
    <p>Essential amino acids for muscle repair</p>
    <strong>6.<a href="https://unfitmart.com/product/UB02100312/bolt-nutrition-eaa/blue-berry-blast/300-gm?productid=249" target="_blank">Bolt Nutrition Plant Protein</a></strong>
    <p>Complete amino acid profile for optimal recovery</p>
    <strong>7.<a href="https://unfitmart.com/product/UB02100301/bolt-biozyme-plant-protein/piedmont-chocolate/2-lb?productid=243" target="_blank">Bolt Nutrition Plant Protein</a></strong>
    <p>Plant-based protein for a sustainable lifestyle</p>
    <strong>8.<a href="https://unfitmart.com/product/UB02100307/bolt-micronised-glutamine-powder/unflavoured/250-gm?productid=246" target="_blank">Bolt Micronized Glutamine</a></strong>
    <p>Immune support and muscle recovery</p>
    </ul>
    <h2>Ensuring Authenticity</h2>
    
    <p>How to <a href="https://verify.boltnutritions.com/" target="_blank">Check BOLT Item</a></p>
    <p>We give primacy to authenticity at Unfitmart. Whenever one is buying BOLT, there will be a holographic seal on the package that gives authenticity of the product being offered.</p>
    <h2>Choosing the Right BOLT Nutrition Product</h2>
    <p>Not sure which of the BOLT products you might need? Look through our descriptions and reviews, or ask one of our experts to find your fitness match.</p>
    <h3>Very frequent questions</h3>
    <h3>Q: Are BOLT products suitable for vegetarians?</h3>
    <p>A: Most of our products are vegetarian friendly. For details, see the product description.</p>
    <h3>Q: How soon can I get my results with BOLT?</h3>
    <p>A: Results are reported in many users after some three weeks of steady usage.</p>
    <h3>Q: What are the side effects associated with using products containing BOLT?</h3>
    <p>A: Bolt products are carefully designed to be safe. However, one should consult a doctor or health professional whenever given the chance.</p>


          
    
    


    </div>)}
    <button onClick={handleExpansion} className="view-more-btn">
        {isExpaneded ? "View Less" : "View More"} <img className={`icon ${isExpaneded ?  "rotate-180" : ""}`} src={DownCircleIcon} />
      </button>

    </div>
    </div>
  );
};

export default BoltBrandContent;
