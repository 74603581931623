import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ProductCard from '../../layout/product/productCard/productCard';
import '../../layout/product/productCard/productCard.css';
import Loader from '../loader/loadingCard';
import Cookies from "js-cookie";
import moengage from "@moengage/web-sdk";
import { fetchProducts } from '../../../api/prodApi';
import { ReactComponent as LeftArrow } from '../../../assests/icons/LeftArrow.svg';
import { Link } from 'react-router-dom';

const ProductSlide = ({ categoryName, categoryId,setIsToastMessage,setCountItems , data ,reDirectionId,parentCategory}) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true); // State to track loading state
  const [page, setPage] = useState(1); // State for pagination
  const isMounted = useRef(false);
  const [currentSelected, setCurrentSelected] = useState([]);
//console.log(categoryName,data)
  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      loadProducts(page);
    }
  }, [page]);

  const loadProducts = async (page) => {
    setLoading(true);
    try {
      //const products = await fetchProducts(categoryId, categoryName, page);
      setProducts(data);
      setPage(page)
      setLoading(false); // Set loading to false when data is fetched
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false); // Set loading to false on error
    }
  };

  const handleNextClick = () => {
    setPage(prevPage => prevPage + 1); // Increment the page number to fetch next set of products
  };

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    touchThreshold: 100,
    // prevArrow: <HmProdPrevArrow />, // Custom left arrow component
    // nextArrow: <HmProdNextArrow onClick={handleNextClick} />, // Add onClick handler to next arrow
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1

        }
      }
    ]
  };

let categoryReplace = encodeURIComponent(categoryName);
  const handleInitialAddToCart = (product) => {
    let USINData = product?.VariantData && product.VariantData[0] && product.VariantData[0].USIN || product?.PriceQuantity && product.PriceQuantity[0] && product.PriceQuantity[0].USIN;
    setCurrentSelected((prev) => {
      if (prev.includes(USINData)) {
        return prev.filter((each) => each !== USINData);
      } else {
        return [USINData];
      }
    });
  };

  // const categoryReplace = categoryName?.replace(/%20/g, "+");

  // Build the URL for the Link

const handleMoenage = (categoryName) => {
 
    moengage.track_event("Homepage_Click_All", {
        "ID": (Cookies.get("Otp")) ? `+91${JSON.parse(Cookies.get("Otp")).MobileNo}` :"", 
        "Screen Name" : categoryName,
        
       });
  


}


  let url ; //http://localhost:3000/category/Hair%2C+Skin+&+Nails/Glutathione?id=349
  if(reDirectionId && reDirectionId.split("_")[2]){
url=`/best-seller?id=${reDirectionId.split("_")[2]}`
   
  }else if(reDirectionId && parentCategory ){
    url=`/category/${parentCategory}/${categoryName}?id=${reDirectionId}`
       
      }
  else{
    url = `/category/${categoryReplace?.replace(/%20/g, '+')}?viewId=${reDirectionId}`;
   //console.log(url)
  }
 
  return (
    <div className='sports_supple'>
      <div className="hm__product-container page-width">
        <div className="hm__prod__slider-title">
          <p>{categoryName}</p>
          <div className="hm__prod__desktop__arrow">
            <Link to={url} onClick={() => handleMoenage(categoryName)}>See All</Link></div>
          <div className="hm__prod__mobile__arrow">
            <Link to={url}><LeftArrow /></Link></div>
        </div>
      </div>
      <div className='slider_container cm_arrow_side'>
        {loading ? (
          <div className="loading-spinner">
            <div className='product-list'>
              <Loader />
              <Loader />
              <Loader />
              <Loader />
              <Loader />
            </div>
          </div> // Display loader while fetching data
        ) : (
          <Slider {...sliderSettings}>
            {products.map((product) => (
               (
                <ProductCard key={product.id} product={product} onAddtoCart={handleInitialAddToCart}
                  currentSelected={currentSelected} 
                  isOutOfStock={product?.VariantDetails?.[0] ? product?.VariantDetails?.[0]?.InStock : product?.PriceQuantity?.[0]?.InStock  == "no"}
                  setIsToastMessage={setIsToastMessage}
                  setCountItems={setCountItems} from={`Homepage_Product_List_Viewed`}
                  screenName={categoryName}
                  />
              )
            ))}
          </Slider>
        )}
      </div>
    </div>
  );
}

export default ProductSlide;
